import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, DivBlock } from "../modules/Basic";
import { FormBlock, Label } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { startPageTimer, superAdmin, trackPageDuration } from "../utils/function";
import { FormHelperText } from "@material-ui/core";

class AddProductionPoint extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      startTime: null,
      value: "",
      error: null,
    };
  }

  componentDidMount() {
    startPageTimer("Add Production Point");
    this.setState({ startTime: Date.now() });
    this.props?.getProductionPointData();
  }

  componentWillUnmount() {
    trackPageDuration("Add Production Point", this.state.startTime);
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  handleClick = () => {
    const { dispatch } = this.props;
    if (this.state.value?.length > 0) {
      dispatch({
        type: "ADD_PRODUCTION_POINT",
        inputVariables: { value: this.state.value },
      });
      this.setState({ error: null, value: "" });
    } else {
      this.setState({ error: "Please enter a name" });
    }
  };

  render() {
    let { NavBarDropDownIndex, productionPoints, CurrentUser } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 2 : 2,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent />
            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>
              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  position:'relative',
                  height:'100%'
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <Heading
                        className={"h2"}
                        style={{ justifyContent: "center" }}
                        type={"h2"}
                      >
                        Add Production Point
                      </Heading>
                      <Columns
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Column
                          lg={8}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              <DivBlock
                                className={"SuccessDiv"}
                                style={{
                                  color:
                                    this.props?.productionPointMessage ===
                                    "Record created Successfully"
                                      ? "#48c46e"
                                      : "red",
                                }}
                              >
                                <Span className={"success-span"}>
                                  {this.props?.productionPointMessage !== ""
                                    ? this.props?.productionPointMessage
                                    : ""}
                                </Span>
                              </DivBlock>

                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>
                                    Production Point
                                  </Label>
                                  <input
                                    className={"form-control"}
                                    onChange={this.handleChange}
                                    value={this.state.value}
                                  />
                                  <FormHelperText style={{ color: "red" }}>
                                    {this.state.error ? this.state.error : ""}
                                  </FormHelperText>
                                </DivBlock>
                              </FormBlock>
                              <button
                                className={"btn btn-primary"}
                                disabled={
                                  superAdmin?.includes(CurrentUser["email"])
                                    ? false
                                    : true
                                }
                                onClick={this.handleClick}
                                style={
                                  superAdmin?.includes(CurrentUser["email"])
                                    ? {
                                        backgroundColor: "#48c46e",
                                        borderColor: "#48c46e",
                                      }
                                    : {
                                        backgroundColor: "#a0d2a0",
                                        borderColor: "#a0d2a0",
                                      }
                                }
                              >
                                Add Production Point
                              </button>
                            </DivBlock>
                            <div className="production-list-view">
                              <ul>
                                {productionPoints?.map((item) => (
                                  <li>{item?.name}</li>
                                ))}
                              </ul>
                            </div>
                          </DivBlock>
                        </Column>
                      </Columns>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProductionPointData: () => {
      dispatch({ type: "GET_PRODUCTION_POINT" });
      return Promise.resolve();
    },
    getSchoolProductionPointData: () => {
      dispatch({ type: "GET_SCHOOL_PRODUCTION_POINT" });
      return Promise.resolve();
    },
    getPositionsData: () => {
      dispatch({ type: "GET_POSITIONS" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProductionPoint);
