import React from 'react';
import PropTypes from 'prop-types';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import { connect } from 'react-redux';
import TimePicker from 'react-time-picker';

import { onChangeTrigger } from "../../../helpers";

import moment from 'moment';

// eslint-disable-next-line no-redeclare
class DatePickerComponent extends React.Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        const now = new Date();
        this.state = {
            date: {
                year: now.getUTCFullYear(),
                month: now.getUTCMonth() + 1,
                day: now.getUTCDate()
            },
            time: "00:00"
        }
    }

    componentDidMount() {
        this.handleDateChange(this.state.date, true);
    }

    zeroPad(number) {
        if(number < 10) {
            return `0${number}`;
        } else {
            return number;
        }
    }

    getDateTime = () => {
        // const { date, time } = this.state;
        // let dateString = date.year + "-" + this.zeroPad(date.month) + "-" + date.day;

        // let dateTime;
        // if(this.props.withTimePicker && time) {
        //     dateTime = moment(`${dateString} ${time}`, 'YYYY-MM-DD HH:mm:ss');
        // }else {
        //     dateTime = new Date(dateString);
        // }

        // return dateTime.toISOString();
        const { date, time } = this.state;
        const year = date.year;
        const month = this.zeroPad(date.month - 1); // Months in JavaScript are 0-indexed
        const day = this.zeroPad(date.day);
        const [hours, minutes] = time.split(':');
    
        let dateTime;
    
        if (this.props.withTimePicker && time) {
            dateTime = moment(`${year}-${month + 1}-${day} ${time}`, 'YYYY-MM-DD HH:mm:ss');
        } else {
            dateTime = new Date(year, month, day, hours, minutes);
        }
    
        // Adjust the return statement based on your requirements
        return dateTime;
        
    }

    handleDateChange = (date, onLoad) => {
        this.setState({ date }, () => {
            const { dispatch, name, actions, changeInput } = this.props;
            if(name) {
                const dateTimeString = this.getDateTime();
                
                changeInput({ name, value: dateTimeString }).then(() => {
                    if(!onLoad) {
                        onChangeTrigger(actions, dispatch);
                    }
                });
            }
        });
    }

    handleTimeChange = (time) => {
        this.setState({ time }, () => {
            const { dispatch, name } = this.props;
            if(name) {
                const dateTimeString = this.getDateTime();
                dispatch({
                    type: "change_input",
                    payload: {name: name, value: dateTimeString},
                });

                //onChangeTrigger(actions, dispatch);
            }
        });
    }

    render() {
        const {
            className,
            timePickerClassName,
            datePickerClassName,
            withTimePicker,
            style
        } = this.props;

        const { date, time } = this.state;

        let content;
        if(withTimePicker) {
            content = (
                <div className={className} style={style}
                     {...this.props.inline}
                >
                    <DatePicker
                        inputClassName={datePickerClassName}
                        inputPlaceholder={this.props.placeHolder}
                        value={date}
                        onChange={this.handleDateChange}
                        shouldHighlightWeekends
                    />
                    <TimePicker
                        clockIcon={null}
                        clearIcon={null}
                        className={timePickerClassName}
                        onChange={this.handleTimeChange}
                        value={time}
                    />
                </div>
            );
        } else {
            content = (
                <>
                 <div style={{position:'relative'}}>
                    <i className={"fa fa-calendar calendar-icon"} aria-hidden="true"></i>
                    <DatePicker
                    inputClassName={className}
                    inputPlaceholder={this.props.placeHolder}
                    value={date}
                    onChange={this.handleDateChange}
                    shouldHighlightWeekends
                    {...this.props.inline}
                />
                </div>
                </>
            );
        }

        return content;
    }
};

DatePickerComponent.propTypes = {
    style: PropTypes.object,
};
DatePickerComponent.defaultProps = {
    style: {},
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeInput: (payload) => {
            dispatch({ type: 'change_input', payload });
            return Promise.resolve();
        },
        dispatch: dispatch
    }
}

export default connect(null, mapDispatchToProps) (DatePickerComponent);
