import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Button, DivBlock, LinkBlock } from "../modules/Basic";
import { Heading, Span } from "../modules/Typography";
import { SelectDropDown } from "../modules/Forms";

class DepthChartControlsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
        call: '',
    }
}
  render() {
    //let { row, index, errors } = this.props;
    let {
      AllTags,
      DepthChartListsCategoryFilter,
      Emptystring,
      DepthChartType,
      SelectedWeekId,
      SelectedTagId,
      SelectedTypeId,
      SeasonYearData,
      selectedSeasonYear
    } = this.props;
    const { Weeks } = window;

    let value = {id :0 ,name:'All'}
    const handleWeeks = () => {
      let finalOutput = [...[value],...Weeks()]
      return finalOutput;
    }

    
    const handleTags = () =>{
      let valueTags = {label:'All',id:0,}
      let newTag = AllTags && isArray(AllTags) && AllTags?.map(({ tag: label, Id: value, ...rest }) => ({
        label,
        value,
        ...rest,
      }));
      if(newTag){
        let finalOutput = [...[valueTags],...newTag]  
        return finalOutput;
      }
    }

    return (
      <Fragment>
        <DivBlock>
          <DivBlock df-snippet-instance-id={301} style={{}}>
            <DivBlock
              className="game_title"
              style={{
                backgroundColor: "#f8f8fb",
                color: "#ffffff",
                display: "flex",
                marginBottom: "0.5em",
                paddingBottom: "0px",
              }}
            >
              <Heading className={"h2"} style={{fontSize:'30px',fontWeight:'700'}} type={"h2"}>
              Depth Charts
              </Heading>

              <Span className={"h2 h2-span"} style={{fontWeight:600}}>
              Depth Charts /
              </Span>

              <Span className={"h2 h2-inner-span"} style={{}}>
              All Depth Charts
              </Span>
            </DivBlock>
          </DivBlock>

          {/* <DivBlock
            className={"DepthButton-div"}
            df-snippet-instance-id={301}
            style={{}}
            actions={[
              {
                action: "",
                trigger: "",
                timeout: "",
                pollingPeriod: "",
                inputVariables: {},
              },
            ]}
          > */}
          <DivBlock
            className={"dropdown-option depth-chart-wrap"}>
            {/* <Heading className={"h4"} style={{color:'black'}} type={"h4"}>
              Filter by :
            </Heading> */}
            <DivBlock
              className={"dropdown-option dp-dropdown"}
            >
              <Heading
                style={{
                  fontFamily:"'Poppins', sans-serif",
                  fontWeight:"500",
                  fontSize: "14px",
                  marginBottom: '5px',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                }}
                type={"h5"}
              >
                Week
              </Heading>
              <SelectDropDown
                style={{
                  color:'black',
                  borderColor:'white',
                  paddingTop:'6px',
                  paddingBottom:'6px',
                  paddingLeft:'10px',
                  paddingRight:'10px',
                  borderRadius:'5px',
                }}
                className={"form-control"}
                name={"SelectedWeekId"}
                dataSetLabel={"name"}
                dataSetValue={"id"}
                enableReinitialize={true}
                initialValue={SelectedWeekId}
                type={"number"}
                dataSet={handleWeeks()&&isArray(handleWeeks())?handleWeeks():[]}
                options={[]}
                actions={[
                  {
                    action: "Set Depth Chart List Week Filter",
                    trigger: "onchange",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: {
                      "category filter": Emptystring,
                      // "input depth chart list week filter": "",
                      "tag filter": null,
                    },
                  },
                ]}
              ></SelectDropDown>
            </DivBlock>

            <DivBlock
              className={"dropdown-option dp-dropdown"}>
             <Heading
                style={{
                  fontFamily:"'Poppins', sans-serif",
                  fontWeight:"500",
                  fontSize: "14px",
                  marginBottom: '5px',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                }}
                type={"h5"}
              >
                Type
              </Heading>
              <SelectDropDown
                style={{
                  color:'black',
                  borderColor:'white',
                  paddingTop:'6px',
                  paddingBottom:'6px',
                  paddingLeft:'10px',
                  paddingRight:'10px',
                  borderRadius:'5px',
                }}
                className={"form-control"}
                name={"SelectedTypeId"}
                dataSetLabel={"name"}
                dataSetValue={"name"}
                dataSet={DepthChartType}
                enableReinitialize={true}
                initialValue={SelectedTypeId}
                options={[]}
                type={"number"}
                actions={[
                  {
                    action: "Set Depth Chart List Category Filter",
                    trigger: "onchange",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { },
                  },
                ]}
              ></SelectDropDown>
            </DivBlock>

            <DivBlock
              className={"dropdown-option dp-dropdown"}>
              <Heading
                style={{
                  fontFamily:"'Poppins', sans-serif",
                  fontWeight:"500",
                  fontSize: "14px",
                  marginBottom: '5px',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                }}
                type={"h5"}
              >
                Tag
              </Heading>

              <SelectDropDown
                style={{
                  color:'black',
                  borderColor:'white',
                  paddingTop:'6px',
                  paddingBottom:'6px',
                  paddingLeft:'10px',
                  paddingRight:'10px',
                  borderRadius:'5px',
              }}
                className={"form-control"}
                name={"SelectedTagId"}
                type={"number"}
                dataSet={handleTags()}
                initialValue={SelectedTagId}
                enableReinitialize={true}
                options={[]}
                dataSetLabel={"label"}
                dataSetValue={"label"}
                actions={[
                  {
                    action: "Set Depth Chart List Category Filter",
                    trigger: "onchange",
                    timeout: "-1",
                    pollingPeriod: "",
                    inputVariables: {
                      "input category filter":
                        DepthChartListsCategoryFilter,
                      // "input tag filter": row["tag"],
                    },
                  },
                ]}
              ></SelectDropDown>
            </DivBlock>
            <DivBlock className="button-container">

            <DivBlock
              className={"addbtn-div"}
              df-snippet-instance-id={301}
            >
              <LinkBlock
                df-snippet-instance-id={301}
                duration={1000}
                href={"/depth_chart_lists"}
                target={"_self"}
                type={"spa"}
              >
                <Button
                  className={"Depthchart-Div btn btn-reset"}
                  df-snippet-instance-id={301}
                  style={{ marginLeft:'15px',}}
                  actions={[
                    {
                      action: "Reset Depth Chart",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: {
                        "input categoryfilter": null,
                        "input depthchart list week filter": null,
                        "input tagfilter": null,
                        "input tag":'All',
                        "input week":0,
                        "input type":'All',
                      },
                    },
                  ]}
                >
                  Reset
                </Button>
              </LinkBlock>
            </DivBlock>

            <DivBlock
              className={"addbtn-div"}
              df-snippet-instance-id={301}
            >
              <LinkBlock
                df-snippet-instance-id={301}
                duration={1000}
                href={"/injured_players"}
                target={"_self"}
                type={"spa"}
              >
                <Button
                  className={"btn btn-primary Depthchart-div"}
                  df-snippet-instance-id={301}
                  style={{
                    fontWeight:500,
                    backgroundColor:'white',
                    color:'#48c46e',
                    borderWidth:'2px',
                    marginLeft:'15px',
                  }}
                >
                  Injured
                </Button>
              </LinkBlock>
            </DivBlock>

            <DivBlock
              className={"addbtn-div"}
              df-snippet-instance-id={301}
            >
              <LinkBlock
                df-snippet-instance-id={301}
                duration={1000}
                href={"/add_depth_chart"}
                target={"_self"}
                type={"spa"}
              >
                <Button
                  className={"btn btn-primary Depthchart-div"}
                  df-snippet-instance-id={301}
                  disabled={SeasonYearData?.[0]?.year === selectedSeasonYear ? false : true}
                  style={
                    SeasonYearData?.[0]?.year === selectedSeasonYear? 
                    {backgroundColor: "#48c46e",
                    borderColor: "#48c46e",
                    fontWeight:500,
                    marginLeft:'15px',}:
                    {backgroundColor: "#a0d2a0",
                    borderColor: "#a0d2a0",
                    fontWeight:500,
                    marginLeft:'15px'}
                  }
                >
                  Add New Package
                </Button>
              </LinkBlock>
            </DivBlock>

            </DivBlock>
          </DivBlock>
          {/* </DivBlock> */}
        </DivBlock>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(DepthChartControlsComponent);
