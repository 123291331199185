import React, { Fragment } from "react";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import { joyrideSteps } from "../../utils/constant";

const JoyrideComponent = ({ dispatch, ...props }) => {
  const { state } = props;

  // const handleJoyrideCallback = (data) => {
  //   console.log("data: ", data);
  //   if (data.lifecycle === "start") {
  //   }

  //   if (data.lifecycle === "complete" || data.action === "close") {
  //     dispatch({ type: "TOUR_STOP" });
  // localStorage.setItem('joyrideSeen', 'true');
  // }

  // if(data.type === "step:after" && data.index === 0) {
  //   dispatch({ type: "TOUR_STOP"});
  // }else if(data.type === "step:after" && data.index === 0){
  //   dispatch({ type: "TOUR_STOP"});
  // }
  // const targetElement = document.querySelector(data.step.target);
  // if(data.type === "step:after" && data.index === 0) {
  //   dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index+1}});
  // }else

  // if(data.type === "step:after" && data.index === 1){
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("joyride-depth-chart")
  // ) {
  //   dispatch({
  //     type: "SET_NAVBAR_DROPDOWN_INDEX",
  //     inputVariables: {
  //       "input navbar dropdown index":
  //         state?.NavBarDropDownIndex === 0 ? 0 : 0,
  //     },
  //   });
  // this.setState({stepIndex:data.index + 1})
  // dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index+1}});
  // }
  // }else

  // if (data.action === "next" && data.type === "step:after") {
  // dispatch({
  //   type: "TOUR_START",
  //   inputVariables: { run: true, index: data?.index + 1 },
  // });
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("joyride-add-depth-chart")
  // ) {
  //   dispatch({
  //     type: "JOYRIDE_NAVIGATION",
  //     inputVariables: { routeName: "/add_depth_chart" },
  //   });
  // }
  // dispatch({ type: "ADD_DEPTH_CHART_TITLE" });
  // dispatch({
  //   type: "TOUR_START",
  //   inputVariables: { run: true, index: data?.index + 1 },
  // });
  // } else {
  // dispatch({
  //   type: "TOUR_START",
  //   inputVariables: { run: true, index: data?.index + 1 },
  // });
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("test-depth-chart-add-button")
  // ) {
  //   dispatch({ type: "CREATE_DEPTH_CHART" });
  //   // dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index+1}});
  // }
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("test-depth-chart-click-cell")
  // ) {
  //   dispatch({
  //     type: "SET_CURRENT_DEPTH_CHART_POSITION",
  //     inputVariables: {
  //       "current depth chart position input": {
  //         "position id": state.reducer?.dummy_position_id,
  //         rank: state.reducer?.dummy_rank,
  //         "row id": state.reducer?.dummy_row_id,
  //       },
  //     },
  //   });
  //   dispatch({ type: "ADD_DEPTH_CHART_PLAYER" });
  // }
  // // if(targetElement && targetElement.classList.contains('test-depth-chart-name-search')){

  // // }
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("test-depth_chart-submit-player")
  // ) {
  //   dispatch({
  //     type: "SET_DEPTH_CHART_USER",
  //     inputVariables: { Id: state.reducer?.Users?.[0]?.Id },
  //   });
  //   dispatch({
  //     type: "SET_CURRENT_DEPTH_CHART_POSITION",
  //     inputVariables: { "current depth chart position input": null },
  //   });
  // }
  // if (targetElement && targetElement.classList.contains("test-grading")) {
  //   dispatch({
  //     type: "JOYRIDE_NAVIGATION",
  //     inputVariables: { routeName: "/play_grades" },
  //   });
  // }
  // if (
  //   targetElement &&
  //   targetElement.classList.contains(
  //     "test-grading-add-new-game-or-practice"
  //   )
  // ) {
  //   console.log("testing :>> ");
  // }

  // if(data.action === "next" && data.type === 'step:after'){
  //   dispatch({ type: "CREATE_DEPTH_CHART"});
  //   dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index+1}});
  // }
  // }
  // } else if (data.lifecycle === "complete") {
  //   dispatch({ type: "TOUR_STOP" });
  // setState({ run: false, stepIndex: 0, tourActive: false });
  // }

  // else if(data.action === 'next' && data.type === 'step:after'){
  //   console.log('testing :>> ', );
  //   dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index + 1}});
  // }else if(data.action === 'next' && data.type === 'step:after'){
  //   dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index + 1}});
  // }

  // Check if the target element has a specific class name

  // if(targetElement && targetElement.classList.contains('test-depth-chart') ){
  //   dispatch({ type: "SET_NAVBAR_DROPDOWN_INDEX",inputVariables:{
  //     "input navbar dropdown index":
  //      state.reducer?.NavBarDropDownIndex === 0 ? -1 : 0,
  //   } });
  //   this.setState({stepIndex: data?.index + 1})
  //   // dispatch({ type: "TOUR_START"});
  // }
  // if(data.action === 'next' && data.type === 'step:after'){
  //   if(targetElement && targetElement.classList.contains('test-add-depth-chart')){
  //     dispatch({ type: "JOYRIDE_NAVIGATION",inputVariables:{routeName : '/add_depth_chart'} });
  //     // this.setState({runJoyride:true})
  //   }
  // }else if (data.action === 'reset' || data.lifecycle === 'complete') {
  //   dispatch({ type: "TOUR_STOP"});
  // }
  // };

  // const handleStepChange = (data) => {
  //   // Extract relevant information from the data object
  //   const { index, step } = data;
  //   dispatch({
  //     type: "TOUR_START",
  //     inputVariables: { run: true, index: index + 1 },
  //   });
  // };

  const handleJoyrideCallback = (data) => {
    const { action, index, step, type } = data;
    const targetElement = document.querySelector(step.target);

    if (action === "skip" || action === "close") {
      dispatch({ type: "TOUR_STOP" });
    }

    if (action === "next" && type === "step:after") {
      dispatch({
        type: "TOUR_START",
        inputVariables: { run: true, index: index + 1 },
      });
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart")
      ) {
        dispatch({
          type: "SET_NAVBAR_DROPDOWN_INDEX",
          inputVariables: {
            "input navbar dropdown index":
              state?.NavBarDropDownIndex === 0 ? 0 : 0,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-roster") &&
        type === "step:after"
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/roster" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-add-depth-chart") &&
        type === "step:after"
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/add_depth_chart" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-add-button") &&
        type === "step:after"
      ) {
      }
    }
    if (action === "prev" && type === "step:after") {
      dispatch({
        type: "TOUR_START",
        inputVariables: { run: true, index: index - 1 }, // Move to the previous step
      });
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart")
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/roster" },
        });
      }
    }
  };

  return (
    <Fragment>
      <Joyride
        steps={joyrideSteps}
        continuous={true}
        run={state.run}
        stepIndex={state.stepIndex}
        callback={handleJoyrideCallback}
        showSkipButton
        showBackButton={true}
        // hideBackButton
        disableOverlayClose
        // scrollToSteps={false} // Disable automatic scrolling to steps
        scrollToFirstStep // Disable automatic scrolling to the first step
        scrollOffset={20} // Adjust scroll offset if necessary
        showProgress={true}
        // disableScrollParentFix={true} // Prevent parent elements from being scrolled
        disableScrolling={true} // Prevent automatic scrolling
        styles={{
          buttonSkip: {
            display: 'block',
            position: 'absolute',
            bottom: '15px',
            right: '140px',
          },
          buttonBack: {
            display: 'block',
            position: 'absolute',
            bottom: '15px',
            left: '15px',
          },
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = function (state) {
  return {
    state: state.reducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoyrideComponent);
