import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, DivBlock, LinkBlock, List, ListItem } from "../modules/Basic";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import PlayerSideNavComponent from "../components/PlayerSideNavComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { Label } from "../modules/Forms";
import { startPageTimer, trackPageDuration } from "../utils/function";

class PlayerDepthChartsPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      startTime: null,
    };
  }

  componentDidMount() {
    startPageTimer('Player Depth Charts List');
    this.setState({ startTime: Date.now() });
    if (!this.props.PlayerDepthChartData) {
      this.props.depthChartData();
    }
  }
  
  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
        this.props.getDepthChartData();
        this.props.depthChartData();
      // dispatch({ type: "LOADING_EVENT",inputVariables:{loader:false} });
    }
  }

  componentWillUnmount(){
   trackPageDuration('Player Depth Charts List',this.state.startTime)
  }

  render() {
    let {
      BaseDepthChartsPlayer,
      DepthChartListsCategoryFilter,
      PlayerDepthChartData,
      selectedSeasonYear
    } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Depth Chart By Player",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Base Depth Chart By Player",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              data-snippet-id={297}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <PlayerSideNavComponent
                data-snippet-id={302}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></PlayerSideNavComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  position:'relative',
                  height:'100%'
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <DivBlock>
                        <Heading className={"h2"} type={"h1"}>
                          Depth Charts
                        </Heading>
                      </DivBlock>

                      <DivBlock className={"mb-4"}>
                        <Heading className={"h3"} type={"h3"}>
                          Base Packages
                        </Heading>

                        <List style={{ marginBottom: "0px", marginTop: "0px" }}>
                          {BaseDepthChartsPlayer && BaseDepthChartsPlayer.length > 0 ? (
                            BaseDepthChartsPlayer &&
                            isArray(BaseDepthChartsPlayer) &&
                            BaseDepthChartsPlayer.map((row, index) => {
                              return (
                                <Fragment>
                                  <ListItem style={{ listStyle: "square" }}>
                                    <Heading className={"h4"} type={"h4"}>
                                      {row["title"]}
                                    </Heading>
                                    <LinkBlock
                                      className={"a"}
                                      duration={1000}
                                      href={
                                        "/player_depth_chart?depthchartid=" +
                                        row["id"] +
                                        "&base=true" + '&year=' + selectedSeasonYear
                                      }
                                      target={"_self"}
                                      type={"spa"}
                                    >
                                      <DivBlock
                                        style={{
                                          fontFamily: "'Poppins', sans-serif",
                                        }}
                                      >
                                        <Span className={"a package"}>
                                          {row["title"]}
                                        </Span>
                                      </DivBlock>
                                    </LinkBlock>
                                  </ListItem>
                                </Fragment>
                              );
                            })
                          ) : (
                            <Label>No assigned packages.</Label>
                          )}
                        </List>
                      </DivBlock>

                      {PlayerDepthChartData &&
                        isArray(PlayerDepthChartData) &&
                        PlayerDepthChartData.map((row, index) => {
                          return (
                            <Fragment key={index}>
                              <DivBlock className={"mb-4"}>
                                {row["charts"] ? (
                                  <DivBlock className={"border-bottom"}>
                                    <Heading className={"h3"} type={"h3"}>
                                      {"Week " + row["week"] + " Packages"}
                                    </Heading>
                                  </DivBlock>
                                ) : null}
                                <DivBlock>
                                  {row["charts"] &&
                                    isArray(row["charts"]) &&
                                    row["charts"].map((row, index) => {
                                      return (
                                        <Fragment key={index}>
                                          <DivBlock>
                                            <If
                                              condition={
                                                DepthChartListsCategoryFilter ===
                                                  "" ||
                                                DepthChartListsCategoryFilter ===
                                                  row["category"]
                                              }
                                              show={true}
                                            >
                                              <Then>
                                                <DivBlock className={"pt-1"}>
                                                  <Heading
                                                    className={"h4"}
                                                    type={"h4"}
                                                  >
                                                    {row["category"]}
                                                  </Heading>

                                                  <List
                                                    style={{
                                                      listStyle: "square",
                                                      marginBottom: "0px",
                                                      marginTop: "0px",
                                                    }}
                                                  >
                                                    {row["charts"] &&
                                                      isArray(row["charts"]) &&
                                                      row["charts"].map(
                                                        (row, index) => {
                                                          return (
                                                            <Fragment
                                                              key={index}
                                                            >
                                                              <ListItem>
                                                                <LinkBlock
                                                                  className={
                                                                    "a"
                                                                  }
                                                                  duration={
                                                                    1000
                                                                  }
                                                                  href={
                                                                    "/player_depth_chart?depthchartid=" +
                                                                    row["id"] + '&year=' + selectedSeasonYear
                                                                  }
                                                                  target={
                                                                    "_self"
                                                                  }
                                                                  type={"spa"}
                                                                >
                                                                  <DivBlock>
                                                                    <Span
                                                                      className={
                                                                        "a package"
                                                                      }
                                                                      actions={[
                                                                        {
                                                                          action:
                                                                            "Set Depth Chart",
                                                                          trigger:
                                                                            "onclick",
                                                                          timeout:
                                                                            "",
                                                                          pollingPeriod:
                                                                            "",
                                                                          inputVariables:
                                                                            {
                                                                              "depth chart":
                                                                                row,
                                                                            },
                                                                        },
                                                                      ]}
                                                                    >
                                                                      {
                                                                        row[
                                                                          "title"
                                                                        ]
                                                                      }
                                                                    </Span>
                                                                  </DivBlock>
                                                                </LinkBlock>
                                                              </ListItem>
                                                            </Fragment>
                                                          );
                                                        }
                                                      )}
                                                  </List>
                                                </DivBlock>
                                              </Then>
                                            </If>
                                          </DivBlock>
                                        </Fragment>
                                      );
                                    })}
                                </DivBlock>
                              </DivBlock>
                            </Fragment>
                          );
                        })}
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    depthChartData: () => {
      dispatch({ type: "GET_DEPTH_CHART_BY_PLAYER" });
      return Promise.resolve();
    },
    getDepthChartData: () => {
      dispatch({ type: "GET_BASE_DEPTH_CHART_BY_PLAYER" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerDepthChartsPage);
