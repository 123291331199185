/* eslint-disable no-useless-concat */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import DepthChartControlsComponent from "../components/DepthChartControlsComponent";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { FormBlock, Label } from "../modules/Forms";
import { handleDate, startPageTimer, trackPageDuration } from "../utils/function";

class DepthChartListsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
    };
  }

  componentDidMount(){
    startPageTimer('Depth Chart List');
    this.setState({ startTime: Date.now() });
  }
  
  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
      this.props.getDepthChartData();
      this.props.getGroupsData();
      this.props.getTagsData();
      // dispatch({ type: "LOADING_EVENT",inputVariables:{loader:false} });
    }
  }
  
  componentWillUnmount(){
    trackPageDuration('Depth Chart List',this.state.startTime)
  }
  render() {
    let {
      BaseDepthCharts,
      DepthChartListWeekFilter,
      DepthChartListsCategoryFilter,
      DepthChartListsTagFilter,
      GroupedDepthCharts,
      NavBarDropDownIndex,
      DepthChartToDelete,
      CurrentDepthChartData,
      SelectedWeekId,
      SelectedTypeId,
      SelectedTagId,
      BaseDepthChartSorting,
      AllDepthChartSorting,
      DepthChartToDuplicate,
      selectedSeasonYear,
      isDefaultLoaded,
      SeasonYearData,
    } = this.props;
    const { FilterDepthCharts } = window;
    
    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Base Depth Charts",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Grouped Charts",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {isDefault:true},
            },
            {
              action: "Get Tags",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Reset Depth Chart",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input categoryfilter": "",
                "input depthchart list week filter": null,
                "input tagfilter": null,
              },
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 0 : 0,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
            context={{
              elements: {
                144: { type: "Body" },
                145: { type: "DivBlock" },
                146: { type: "DivBlock" },
                151: { type: "DivBlock" },
                152: { type: "DivBlock" },
                153: { type: "List" },
                154: { type: "ListItem" },
                155: { type: "LinkBlock" },
                156: { type: "DivBlock" },
                157: { type: "Span" },
                158: { type: "ListItem" },
                159: { type: "LinkBlock" },
                160: { type: "DivBlock" },
                161: { type: "Span" },
                162: { type: "ListItem" },
                163: { type: "LinkBlock" },
                164: { type: "DivBlock" },
                165: { type: "Span" },
                166: { type: "DivBlock" },
                167: { type: "DivBlock" },
                168: { type: "DivBlock" },
                169: { type: "DivBlock" },
                184: { type: "LinkBlock" },
                185: { type: "DivBlock" },
                186: { type: "Image" },
                187: { type: "DivBlock" },
                188: { type: "Button" },
                189: { type: "If" },
                190: { type: "Then" },
                191: { type: "DivBlock" },
                192: { type: "DivBlock" },
                193: { type: "Span" },
              },
              getters: {},
              listeners: [
                { name: "mouseOver.boundaries" },
                { name: "mouseOut.boundaries" },
                { name: "dragEnter" },
                { name: "dragLeave" },
                { name: "dragStart" },
                { name: "dragEnd" },
                { name: "mouseDown.mousemenu" },
                { name: "showSettingsBox.desk" },
                { name: "textChanged.desk" },
                { name: "editTextMode.desk" },
                { name: "componentClick.desk" },
                { name: "pathnameChanged.desk" },
                { name: "cut.desk" },
                { name: "copy.desk" },
                { name: "editSnippet.desk" },
                { name: "clone.desk" },
                { name: "delete.desk" },
                { name: "before.desk" },
                { name: "after.desk" },
                { name: "first.desk" },
                { name: "last.desk" },
                { name: "replace.desk" },
                { name: "select.desk" },
                { name: "highlight.desk" },
                { name: "dropZoneHover" },
                { name: "onDragEnd" },
                { name: "copyStyleProps" },
                { name: "pasteStyleProps" },
                { name: "updateSelectedProps" },
              ],
              triggerCache: {
                componentClick: [{ name: "componentClick.desk" }],
                editSnippet: [{ name: "editSnippet.desk" }],
                editTextMode: [{ name: "editTextMode.desk" }],
                mouseDown: [{ name: "mouseDown.mousemenu" }],
                mouseOut: [{ name: "mouseOut.boundaries" }],
                mouseOver: [{ name: "mouseOver.boundaries" }],
                pathnameChanged: [{ name: "pathnameChanged.desk" }],
                textChanged: [{ name: "textChanged.desk" }],
              },
            }}
            data-snippet-id={297}
            df-snippet-instance-id={297}
            draggable={true}
            eleKey={"146"}
            inline={{ "df-snippet-instance-id": 297, draggable: true }}
            isEdit={true}
            root-snippet={true}
            style={{
              alignItems: "center",
              backgroundColor: "#000000",
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px",
            }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  position:'relative',
                  height:'100%'
                }}
              >
                <DivBlock className="testing"
                  style={{
                    backgroundColor: "#f8f8fb",
                    bottom: "0px",
                    display: "inline-block",
                    left: "0px",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                    <DivBlock className={"body-main"} style={{}}>
                      <DepthChartControlsComponent
                        data-snippet-id={301}
                        style={{ marginBottom: "1rem" }}
                      ></DepthChartControlsComponent>
                      {isDefaultLoaded ? null :
                      <>
                      {SelectedWeekId === 0 && 
                      (SelectedTypeId === 'All' || SelectedTypeId === 0 ) && 
                      (SelectedTagId === 'All' || SelectedTagId === 0) ? 
                      <DivBlock className={"mb-4"}>
                        <Heading className={"h3"} style={{fontWeight:'700',marginBottom:'20px'}} type={"h3"}>
                          Base Packages
                        </Heading>
                        
                        <DivBlock className="table-responsive-sm">      
                          <table className={"table"}>
                            <thead>
                              <tr className={"thead-bg header-line-height"}>                        
                                <th className={"th-border"} style={{width:'250px',textAlign:'left'}}>
                                  <Span className={"Sorting-span th-span"}
                                    actions={[
                                    {
                                      action: "Sort Base Depth Chart Table",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        sortingField: "title",
                                        inputSorting: BaseDepthChartSorting !== true,
                                      },
                                    },
                                  ]}>
                                    Name
                                  </Span>
                                </th>
                                <th className={"th-border"} style={{width:'150px',textAlign:'left'}}>
                                  <Span className={"Sorting-span th-span"}
                                    actions={[
                                      {
                                        action: "Sort Base Depth Chart Table",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          sortingField: "category",
                                          inputSorting: BaseDepthChartSorting !== true,
                                        },
                                      },
                                    ]}>
                                    Type
                                  </Span>
                                </th>
                                <th className={"th-border"} style={{width:'200px',textAlign:'left'}}>
                                  <Span className={"Sorting-span th-span"}
                                   actions={[
                                    {
                                      action: "Sort Base Depth Chart Table",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        sortingField: "Created At",
                                        inputSorting: BaseDepthChartSorting !== true,
                                      },
                                    },
                                  ]}>
                                    Date Created
                                  </Span>
                                </th>
                                <th className={"th-border"} style={{textAlign:'left'}}>
                                  <Span className={"th-span"}>
                                    Action
                                  </Span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {BaseDepthCharts &&
                                isArray(BaseDepthCharts) &&
                                BaseDepthCharts.map((row, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <tr className={(index+1) % 2 === 0 ? "table-data-after" : "table-data-before"}>
                                        <td
                                          style={{
                                            borderBottomColor: "#000000",
                                            borderBottomStyle: "none",
                                            borderBottomWidth: "",
                                            borderLeftColor: "#000000",
                                            borderLeftStyle: "none",
                                            borderLeftWidth: "",
                                            borderRightColor: "#000000",
                                            borderRightStyle: "none",
                                            borderRightWidth: "",
                                            borderStyle: "none",
                                            borderTopColor: "#000000",
                                            borderTopStyle: "none",
                                            borderTopWidth: "",
                                            paddingBottom: "10px",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          <LinkBlock
                                            className={"a"}
                                            duration={1000}
                                            href={
                                              "/depth_chart?depthchartid=" +
                                              row["id"] +
                                              "&base=true"+'&year=' +selectedSeasonYear
                                            }
                                            target={"_self"}
                                            type={"spa"}
                                          >
                                              <Span
                                                className={"depth-table-span"}
                                                style={{textDecoration: 'underline',color:'#4bb06a',}}
                                                actions={[
                                                  {
                                                    action: "Set Depth Chart",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      "depth chart": row,
                                                    },
                                                  },
                                                ]}
                                              >
                                                {row["title"]}
                                              </Span>
                                          </LinkBlock>
                                        </td>
                                        <td
                                          style={{
                                            borderBottomColor: "#000000",
                                            borderBottomStyle: "none",
                                            borderBottomWidth: "",
                                            borderLeftColor: "#000000",
                                            borderLeftStyle: "none",
                                            borderLeftWidth: "",
                                            borderRightColor: "#000000",
                                            borderRightStyle: "none",
                                            borderRightWidth: "",
                                            borderStyle: "none",
                                            borderTopColor: "#000000",
                                            borderTopStyle: "none",
                                            borderTopWidth: "",
                                            paddingBottom: "10px",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "10px",
                                            alignItems:'flex-start',
                                          }}
                                        >
                                          <Span className={"depth-table-span"}>
                                            {row["category"]}
                                          </Span>
                                        </td>
                                        <td
                                          style={{
                                            borderBottomColor: "#000000",
                                            borderBottomStyle: "none",
                                            borderBottomWidth: "",
                                            borderLeftColor: "#000000",
                                            borderLeftStyle: "none",
                                            borderLeftWidth: "",
                                            borderRightColor: "#000000",
                                            borderRightStyle: "none",
                                            borderRightWidth: "",
                                            borderStyle: "none",
                                            borderTopColor: "#000000",
                                            borderTopStyle: "none",
                                            borderTopWidth: "",
                                            paddingBottom: "10px",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "10px",
                                            alignItems:'flex-start',
                                          }}
                                        >
                                          <Span className={"depth-table-span"} >
                                            {handleDate(row?.["Created At"])}
                                          </Span>
                                        </td>
                                        <td
                                          style={{
                                            borderBottomColor: "#000000",
                                            borderBottomStyle: "none",
                                            borderBottomWidth: "",
                                            borderLeftColor: "#000000",
                                            borderLeftStyle: "none",
                                            borderLeftWidth: "",
                                            borderRightColor: "#000000",
                                            borderRightStyle: "none",
                                            borderRightWidth: "",
                                            borderStyle: "none",
                                            borderTopColor: "#000000",
                                            borderTopStyle: "none",
                                            borderTopWidth: "",
                                            paddingBottom: "10px",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "10px",
                                            alignItems:'flex-start',
                                          }}
                                        >
                                          <DivBlock style={{display:'flex'}}>
                                          {SeasonYearData?.[0]?.year === selectedSeasonYear ?
                                          <>
                                            <DivBlock>
                                            <Span className={"depth-table-span"} 
                                              style={{fontSize:'18px',marginRight:'25px'}}
                                              actions={[
                                                {
                                                  action: "Set Duplicate Depth Chart",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    "duplicate depth chart id":
                                                      row?.id,
                                                      "duplicate depth chart name": row?
                                                      row?.title+'_Duplicate':null,
                                                      "duplicate depth chart" : true,
                                                      "duplicate base depth chart" : true,
                                                      "duplicate all depth chart" : false,
                                                  },
                                                },
                                              ]}
                                              >
                                                <i className="fa fa-files-o"
                                                style={{cursor:"pointer"}}/>
                                            </Span>
                                            </DivBlock>
                                            <DivBlock>
                                            <Span className={"depth-table-span"} 
                                              style={{fontSize:'18px',marginRight:'25px'}}
                                              actions={[
                                                {
                                                  action: "Set Delete Depth Chart",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    "Depth Chart Id":
                                                      row
                                                        ? row["id"]
                                                        : "",
                                                      "Depth Chart Data":{
                                                        row
                                                      }

                                                  },
                                                },
                                              ]}>
                                                <i className="fa fa-trash" 
                                                style={{cursor:"pointer"}}/>
                                            </Span>
                                            </DivBlock> </>: null}

                                            <DivBlock>
                                            <LinkBlock
                                            className={"a"}
                                            duration={1000}
                                            href={
                                              "/depth_chart?depthchartid=" +
                                              row["id"] +
                                              "&base=true"+'&year=' +selectedSeasonYear
                                            }
                                            target={"_self"}
                                            type={"spa"}
                                          >
                                            <Span className={"depth-table-span"} 
                                              style={{fontSize:'18px'}}
                                              >
                                                <i className="fa fa-eye" 
                                                style={{cursor:"pointer"}}/>
                                            </Span>
                                            </LinkBlock>  
                                            </DivBlock>
                                          </DivBlock>
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                })}
                            </tbody>
                          </table>
                        </DivBlock>
                      </DivBlock> : null }

                      <DivBlock className={"mb-4"}>
                        <Heading className={"h3"} style={{fontWeight:'700',marginBottom:'20px'}} type={"h3"}>
                        {SelectedWeekId === 0 && 
                      (SelectedTypeId === 'All' || SelectedTypeId === 0 ) && 
                      (SelectedTagId === 'All' || SelectedTagId === 0) ? 
                          "All Other Packages" :  
                          `All ${(SelectedTypeId === 0 || SelectedTypeId === 'All')? '' : `“${SelectedTypeId}”`} depth charts 
                          ${(SelectedTagId === 0 || SelectedTagId === 'All') ? '' : `tagged “${SelectedTagId}”`} 
                          ${SelectedWeekId === 0 ? '' :  `in “Week ${SelectedWeekId}”`}`}
                        </Heading>
                        {GroupedDepthCharts && 
                        isArray(GroupedDepthCharts) && 
                        GroupedDepthCharts.length > 0 ?
                        <DivBlock className="table-responsive-sm">      
                          <table className={"table"}>
                            <thead>
                              <tr className={"thead-bg header-line-height"}>                        
                                <th className={"th-border"} style={{width:'250px',textAlign:'left'}}>
                                  <Span className={"Sorting-span th-span"}
                                    actions={[
                                      {
                                        action: "Sort All Depth Chart Table",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          sortingField: "week",
                                          inputSorting: AllDepthChartSorting !== true,
                                        },
                                      },
                                    ]}>
                                    Week
                                  </Span>
                                </th>
                                <th className={"th-border"} style={{width:'250px',textAlign:'left'}}>
                                  <Span className={"Sorting-span th-span"}
                                    actions={[
                                      {
                                        action: "Sort All Depth Chart Table",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          sortingField: "title",
                                          inputSorting: AllDepthChartSorting !== true,
                                        },
                                      },
                                    ]}>
                                    Name
                                  </Span>
                                </th>
                                <th className={"th-border"} style={{width:'150px',textAlign:'left'}}>
                                  <Span className={"Sorting-span th-span"}
                                    actions={[
                                      {
                                        action: "Sort All Depth Chart Table",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          sortingField: "category",
                                          inputSorting: AllDepthChartSorting !== true,
                                        },
                                      },
                                    ]}>
                                    Type
                                  </Span>
                                </th>
                                <th className={"th-border"} style={{width:'200px',textAlign:'left'}}>
                                  <Span className={"Sorting-span th-span"}
                                    actions={[
                                      {
                                        action: "Sort All Depth Chart Table",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          sortingField: "date",
                                          inputSorting: AllDepthChartSorting !== true,
                                        },
                                      },
                                    ]}>
                                    Date Created
                                  </Span>
                                </th>
                                <th className={"th-border"} style={{textAlign:'left'}}>
                                  <Span className={"th-span"}>
                                    Action
                                  </Span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                            {FilterDepthCharts({
                              charts: GroupedDepthCharts,
                              week: DepthChartListWeekFilter,
                              category: DepthChartListsCategoryFilter,
                              tag: DepthChartListsTagFilter,
                            }) &&
                              isArray(
                                FilterDepthCharts({
                                  charts: GroupedDepthCharts,
                                  week: DepthChartListWeekFilter,
                                  category: DepthChartListsCategoryFilter,
                                  tag: DepthChartListsTagFilter,
                                })
                              ) &&
                              FilterDepthCharts({
                                charts: GroupedDepthCharts,
                                week: DepthChartListWeekFilter,
                                category: DepthChartListsCategoryFilter,
                                tag: DepthChartListsTagFilter,
                              }).map((row, index) => {
                                return (
                                  <Fragment>
                                    <tr className={(index+1) % 2 === 0 ? "table-data-after" : "table-data-before"}>
                                      <td
                                      style={{
                                        borderBottomColor: "#000000",
                                        borderBottomStyle: "none",
                                        borderBottomWidth: "",
                                        borderLeftColor: "#000000",
                                        borderLeftStyle: "none",
                                        borderLeftWidth: "",
                                        borderRightColor: "#000000",
                                        borderRightStyle: "none",
                                        borderRightWidth: "",
                                        borderStyle: "none",
                                        borderTopColor: "#000000",
                                        borderTopStyle: "none",
                                        borderTopWidth: "",
                                        paddingBottom: "10px",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "10px",
                                      }}
                                      >
                                        <Span className={"depth-table-span"}>
                                        {row["week"] ? "Week " +row["week"] : null}
                                      </Span>
                                      </td>
                                      <td
                                        style={{
                                          borderBottomColor: "#000000",
                                          borderBottomStyle: "none",
                                          borderBottomWidth: "",
                                          borderLeftColor: "#000000",
                                          borderLeftStyle: "none",
                                          borderLeftWidth: "",
                                          borderRightColor: "#000000",
                                          borderRightStyle: "none",
                                          borderRightWidth: "",
                                          borderStyle: "none",
                                          borderTopColor: "#000000",
                                          borderTopStyle: "none",
                                          borderTopWidth: "",
                                          paddingBottom: "10px",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <LinkBlock
                                          className={"a"}
                                          duration={1000}
                                          href={
                                            "/depth_chart?depthchartid=" +
                                            row["id"]+ '&year=' +selectedSeasonYear
                                          }
                                          target={"_self"}
                                          type={"spa"}
                                        >
                                            <Span
                                              className={"depth-table-span"}
                                              style={{textDecoration: 'underline',color:'#4bb06a',}}
                                              actions={[
                                                {
                                                  action:"Set Depth Chart",
                                                  trigger:"onclick",
                                                  timeout:"",
                                                  pollingPeriod:"",
                                                  inputVariables:{
                                                    "depth chart":row,
                                                  },
                                                },
                                              ]}
                                            >
                                              {row["title"]}
                                            </Span>
                                        </LinkBlock>
                                      </td>
                                      <td
                                        style={{
                                          borderBottomColor: "#000000",
                                          borderBottomStyle: "none",
                                          borderBottomWidth: "",
                                          borderLeftColor: "#000000",
                                          borderLeftStyle: "none",
                                          borderLeftWidth: "",
                                          borderRightColor: "#000000",
                                          borderRightStyle: "none",
                                          borderRightWidth: "",
                                          borderStyle: "none",
                                          borderTopColor: "#000000",
                                          borderTopStyle: "none",
                                          borderTopWidth: "",
                                          paddingBottom: "10px",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          paddingTop: "10px",
                                          alignItems:'flex-start',
                                        }}
                                      >
                                        <Span className={"depth-table-span"}>
                                          {row["category"]}
                                        </Span>
                                      </td>
                                      <td
                                        style={{
                                          borderBottomColor: "#000000",
                                          borderBottomStyle: "none",
                                          borderBottomWidth: "",
                                          borderLeftColor: "#000000",
                                          borderLeftStyle: "none",
                                          borderLeftWidth: "",
                                          borderRightColor: "#000000",
                                          borderRightStyle: "none",
                                          borderRightWidth: "",
                                          borderStyle: "none",
                                          borderTopColor: "#000000",
                                          borderTopStyle: "none",
                                          borderTopWidth: "",
                                          paddingBottom: "10px",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          paddingTop: "10px",
                                          alignItems:'flex-start',
                                        }}
                                      >
                                        <Span className={"depth-table-span"} >
                                          {row['date'] ? handleDate(row['date']) : ""}
                                        </Span>
                                      </td>
                                      <td
                                        style={{
                                          borderBottomColor: "#000000",
                                          borderBottomStyle: "none",
                                          borderBottomWidth: "",
                                          borderLeftColor: "#000000",
                                          borderLeftStyle: "none",
                                          borderLeftWidth: "",
                                          borderRightColor: "#000000",
                                          borderRightStyle: "none",
                                          borderRightWidth: "",
                                          borderStyle: "none",
                                          borderTopColor: "#000000",
                                          borderTopStyle: "none",
                                          borderTopWidth: "",
                                          paddingBottom: "10px",
                                          paddingLeft: "0.75rem",
                                          paddingRight: "0.75rem",
                                          paddingTop: "10px",
                                          alignItems:'flex-start',
                                        }}
                                      >
                                        {row["title"]?
                                        <DivBlock style={{display:'flex'}}>
                                          {SeasonYearData?.[0]?.year === selectedSeasonYear ?
                                          <>
                                            <DivBlock>
                                            <Span className={"depth-table-span"} 
                                              style={{fontSize:'18px',marginRight:'25px'}}
                                              actions={[
                                                {
                                                  action: "Set Duplicate Depth Chart",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    "duplicate depth chart id":
                                                      row?.id,
                                                      "duplicate depth chart name": row?
                                                      row?.title+'_Duplicate':null,
                                                      "duplicate depth chart" : true,
                                                      "duplicate all depth chart" : true,
                                                      "duplicate base depth chart" : false,
                                                  },
                                                },
                                              ]}
                                              >
                                                <i className="fa fa-files-o"
                                                style={{cursor:"pointer"}}/>
                                            </Span>
                                            </DivBlock>
                                            <DivBlock>
                                            <Span className={"depth-table-span"} 
                                              style={{fontSize:'18px',marginRight:'25px'}}
                                              actions={[
                                                {
                                                  action: "Set Delete Depth Chart",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    "Depth Chart Id":
                                                      row
                                                        ? row["id"]
                                                        : "",
                                                      "Depth Chart Data":{
                                                        row
                                                      }

                                                  },
                                                },
                                              ]}>
                                                <i className="fa fa-trash" 
                                                style={{cursor:"pointer"}}/>
                                            </Span>
                                            </DivBlock> </> : null}
                                            <DivBlock>
                                            <LinkBlock
                                          className={"a"}
                                          duration={1000}
                                          href={
                                            "/depth_chart?depthchartid=" +
                                            row[
                                              "id"
                                            ]+'&year=' +selectedSeasonYear
                                          }
                                          target={"_self"}
                                          type={"spa"}
                                        >
                                            <Span className={"depth-table-span"} 
                                              style={{fontSize:'18px'}}
                                              >
                                                <i className="fa fa-eye" 
                                                style={{cursor:"pointer"}}/>
                                            </Span>
                                            </LinkBlock>
                                            </DivBlock>
                                          </DivBlock>: null}
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </DivBlock> 
                          :  <Span
                            style={{
                              fontFamily:"'Poppins', sans-serif",
                            }}
                        >
                          No Depth Charts added.
                        </Span>}
                      </DivBlock></>}
                    </DivBlock>
                </DivBlock>
              </DivBlock>

              <If condition={DepthChartToDelete ? true : false} show={false}>
                <Then>
                  <DivBlock
                    className={"modal"}
                  >
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-dialog"}>
                        <DivBlock className={"modal-content"}>
                          <DivBlock className={"modal-header"}>
                            <Heading
                              className="modal-heading"
                              type={"h5"}
                            >
                              Delete Depth Chart
                            </Heading>

                            <Span
                              className={"close"}
                              actions={[
                                {
                                  action: "Set Delete Depth Chart",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "Depth Chart Id": null,
                                  },
                                },
                              ]}
                            >
                              x
                            </Span>
                          </DivBlock>

                          <DivBlock className={"modal-body"}>
                            <FormBlock
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <DivBlock>
                                <Label className={"label"}>
                                  Are you sure you want to delete this package?
                                </Label>
                              </DivBlock>

                              <DivBlock
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  className={"btn btn-reset DeleteBtn"}
                                  style={{}}
                                  actions={[
                                    {
                                      action: "Set Delete Depth Chart",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: { "Depth Chart Id": null },
                                    },
                                  ]}
                                >
                                  Cancel
                                </Button>

                                <Button
                                  className={"btn btn-primary"}
                                  style={{}}
                                  actions={[
                                    {
                                      action: "Delete Depth Chart",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        "depth chart id to delete":
                                        CurrentDepthChartData
                                            ? CurrentDepthChartData["id"]
                                            : "",
                                            "Depth Chart Id": null,
                                      },
                                    },
                                  ]}
                                >
                                  Yes
                                </Button>
                              </DivBlock>
                            </FormBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </Then>
              </If>
              <If condition={DepthChartToDuplicate ? true : false} show={false}>
                <Then>
                  <DivBlock
                    className={"modal"}
                  >
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-dialog"}>
                        <DivBlock className={"modal-content"}>
                          <DivBlock className={"modal-header"}>
                            <Heading
                              className="modal-heading"
                              type={"h5"}
                            >
                              Duplicate Depth Chart
                            </Heading>

                            <Span
                              className={"close"}
                              actions={[
                                {
                                  action: "Set Duplicate Depth Chart",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "duplicate depth chart id":null,
                                    "duplicate depth chart name": null,
                                    "duplicate depth chart" : false,
                                  },
                                },
                              ]}
                            >
                              x
                            </Span>
                          </DivBlock>

                          <DivBlock className={"modal-body"}>
                            <FormBlock
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <DivBlock>
                                <Label className={"label"}>
                                  Are you sure you want to duplicate this package?
                                </Label>
                              </DivBlock>

                              <DivBlock
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  className={"btn btn-reset DeleteBtn"}
                                  style={{}}
                                  actions={[
                                    {
                                      action: "Set Duplicate Depth Chart",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: { 
                                        "duplicate depth chart id":null,
                                        "duplicate depth chart name": null,
                                        "duplicate depth chart" : false,
                                      },
                                    },
                                  ]}
                                >
                                  Cancel
                                </Button>

                                <Button
                                  className={"btn btn-primary"}
                                  style={{}}
                                  actions={[
                                    {
                                      action: "Duplicate Depth Chart",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                       'duplicate depth chart':false
                                      },
                                    },
                                  ]}
                                >
                                  Yes
                                </Button>
                              </DivBlock>
                            </FormBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </Then>
              </If>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDepthChartData: () => {
      dispatch({ type: "GET_BASE_DEPTH_CHARTS" });
      return Promise.resolve();
    },
    getGroupsData: () => {
      dispatch({ type: "GET_GROUPED_CHARTS", inputVariables:{isDefault:true} });
      return Promise.resolve();
    },
    getTagsData: () => {
      dispatch({ type: "GET_TAGS" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepthChartListsPage);
