import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, DivBlock } from "../modules/Basic";
import { Heading } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { Column, Columns } from "../modules/Layout";
import { Label } from "../modules/Forms";
import { startPageTimer, trackPageDuration } from "../utils/function";

class SupportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
      // message:'',
    };
  }

  componentDidMount(){
    startPageTimer('Support');
    this.setState({ startTime: Date.now() });
  }

  componentWillUnmount(){
    trackPageDuration('Support',this.state.startTime)
  }

  render() {
    let { NavBarDropDownIndex } = this.props;
    return (
      <Fragment>
        <Body
          actions={[
            {
              action: "Get Support Page",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 5 : 99,
              },
            },
          ]}
        >
          <DivBlock
            context={{
              elements: {
                7303: { type: "Body" },
                7304: { type: "DivBlock" },
                7738: { type: "DivBlock" },
                7749: { type: "DivBlock" },
                7750: { type: "DivBlock" },
                7812: { type: "DivBlock" },
                7813: { type: "DivBlock" },
                7814: { type: "DivBlock" },
                7815: { type: "DivBlock" },
                7816: { type: "DivBlock" },
                7817: { type: "DivBlock" },
                7818: { type: "Heading" },
                7819: { type: "Span" },
                7820: { type: "Span" },
                7821: { type: "Columns" },
                7822: { type: "Column" },
                7823: { type: "DivBlock" },
                7824: { type: "Button" },
                7825: { type: "table" },
                7826: { type: "thead" },
                7827: { type: "tr" },
                7828: { type: "th" },
                7829: { type: "Span" },
                7830: { type: "th" },
                7831: { type: "Span" },
                7832: { type: "tbody" },
                7833: { type: "tr" },
                7834: { type: "td" },
                7835: { type: "Span" },
                7836: { type: "td" },
                7837: { type: "Span" },
                7838: { type: "If" },
              },
              getters: {},
              listeners: [
                { name: "mouseOver.boundaries" },
                { name: "mouseOut.boundaries" },
                { name: "dragEnter" },
                { name: "dragLeave" },
                { name: "dragEnd" },
                { name: "mouseDown.mousemenu" },
                { name: "showSettingsBox.desk" },
                { name: "textChanged.desk" },
                { name: "editTextMode.desk" },
                { name: "componentClick.desk" },
                { name: "pathnameChanged.desk" },
                { name: "cut.desk" },
                { name: "copy.desk" },
                { name: "create_prefab.desk" },
                { name: "create_partial.desk" },
                { name: "editSnippet.desk" },
                { name: "clone.desk" },
                { name: "delete.desk" },
                { name: "before.desk" },
                { name: "after.desk" },
                { name: "first.desk" },
                { name: "last.desk" },
                { name: "replace.desk" },
                { name: "select.desk" },
                { name: "highlight.desk" },
                { name: "dropZoneHover" },
                { name: "dragEnd" },
                { name: "dragStart" },
                { name: "copyStyleProps" },
                { name: "pasteStyleProps" },
                { name: "updateSelectedProps" },
                { name: "dragStart" },
              ],
              triggerCache: {
                after: [{ name: "after.desk" }],
                componentClick: [{ name: "componentClick.desk" }],
                create_partial: [{ name: "create_partial.desk" }],
                editSnippet: [{ name: "editSnippet.desk" }],
                editTextMode: [{ name: "editTextMode.desk" }],
                last: [{ name: "last.desk" }],
                mouseDown: [{ name: "mouseDown.mousemenu" }],
                mouseOut: [{ name: "mouseOut.boundaries" }],
                mouseOver: [{ name: "mouseOver.boundaries" }],
                pathnameChanged: [{ name: "pathnameChanged.desk" }],
                replace: [{ name: "replace.desk" }],
                "showSettingsBox.desk": [{ name: "showSettingsBox.desk" }],
                textChanged: [{ name: "textChanged.desk" }],
              },
            }}
            df-snippet-instance-id={457}
            draggable={false}
            eleKey={"7304"}
            inline={{ "df-snippet-instance-id": 457, draggable: false }}
            isEdit={true}
            root-snippet={true}
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={false}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: false }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>
            <DivBlock
              className={"body-container"}
              df-snippet-instance-id={457}
              style={{}}
            >
              <SideNavBarComponent
                data-snippet-id={298}
                df-snippet-instance-id={457}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  position:'relative',
                  height:'100%'
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock className={"body-main"}>
                    <DivBlock>
                      <Heading
                        className={"h2"}
                        style={{ marginLeft: "10px" }}
                        type={"h2"}
                      >
                        Support
                      </Heading>
                      <Columns style={{ display: "flex" }}>
                        <Column lg={8} md={8} sm={12} style={{}} xs={12}>
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              {/* <Heading
                        df-snippet-instance-id={457}
                        style={{
                          marginBottom: 0,
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: 0,
                        }}
                        type={"h1"}
                      >
                        Support
                      </Heading> */}

                              <DivBlock df-snippet-instance-id={457} style={{}}>
                                <Label
                                  className={"label"}
                                  df-snippet-instance-id={457}
                                  style={{
                                    marginBottom: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    marginTop: 5,
                                  }}
                                >
                                  {/* Thank you for using CoachTools.

Should you have any questions or concerns, please contact us via the support chat buy clicking the green icon in the bottom right corner and someone from our support team will reply as soon as possible. */}
                                  Thank you for using CoachTools. Below is an
                                  introductory video explaining how to set up
                                  your account and start entering data for your
                                  program.
                                  <br />
                                  <br />
                                  Should you have any questions or concerns,
                                  please contact us via the support chat buy
                                  clicking the green icon in the bottom right
                                  corner and someone from our support team will
                                  reply as soon as possible.
                                  <br />
                                  <br />
                                  <a
                                    href="https://tinyurl.com/3yfjjhc3"
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    Click Here to View Account Setup Video
                                  </a>
                                </Label>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                    </DivBlock>
                    <DivBlock>
                      <Heading
                        className={"h2"}
                        style={{ marginLeft: "10px" }}
                        type={"h2"}
                      >
                        FAQs
                      </Heading>
                      <Columns style={{ display: "flex" }}>
                        <Column lg={8} md={8} sm={12} style={{}} xs={12}>
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              {/* <Heading
                          df-snippet-instance-id={457}
                          style={{
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            marginTop: 15,
                          }}
                          type={"h2"}
                        >
                          FAQs
                        </Heading> */}

                              <DivBlock df-snippet-instance-id={457} style={{}}>
                                <Label
                                  className={"label"}
                                  df-snippet-instance-id={457}
                                  style={{
                                    marginBottom: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    marginTop: 5,
                                  }}
                                >
                                  <ul>
                                    <li>
                                      {" "}
                                      Can I upload more than one roster at a
                                      time?
                                    </li>
                                    Only one roster can be used per season.
                                    However, rosters will be saved between
                                    seasons.
                                    <br />
                                    <br />
                                    <li>
                                      {" "}
                                      Can I add new coaches / remove old coaches
                                      during coaching staff changes?
                                    </li>
                                    Yes, you can make changes to the coaches
                                    with access at any time.
                                    <br />
                                    <br />
                                    <li>
                                      How many ‘tags’ can I apply to a depth
                                      chart package?
                                    </li>
                                    You canadd as many tags as you feel
                                    necessary for a package.
                                    <br />
                                    <br />
                                    <li>
                                      {" "}
                                      Can I remove a currently associated ‘tag’
                                      from a package?
                                    </li>
                                    Tags can be added/removed using our Manage
                                    Tags feature.
                                    <br />
                                    <br />
                                    <li>
                                      {" "}
                                      How can I organize my depth charts?
                                    </li>
                                    We offer several options to organize depth
                                    charts with Tags, Unit Filters, and Week
                                    Filters.
                                    <br />
                                    <br />
                                    <li>
                                      {" "}
                                      Can I upload and share files with the
                                      coaching staffand/or players?
                                    </li>
                                    Yes, multiple files can be uploaded and
                                    shared with the coaching staff and/or
                                    players.
                                    <br />
                                    <br />
                                    <li>
                                      {" "}
                                      How many intangibles can I use to grade?
                                    </li>
                                    There is no limit on the number of
                                    intangibles you can use for grading.
                                    <br />
                                    <br />
                                    <li>
                                      {" "}
                                      Do I need to grade every single player for
                                      every single play?
                                    </li>
                                    No, you do not have to grade every player on
                                    every play. Our system will take the
                                    information you have graded and create
                                    accurate reports regardless of any empty
                                    sections.
                                    <br />
                                    <br />
                                    <li> Can I print the depth charts?</li>
                                    Depth Charts cannot be printed but they can
                                    be shared with players/coaches within the
                                    site
                                    <br />
                                    <br />
                                    <li> How do I set my grading scale?</li>
                                    You can set your grading scale by accessing
                                    your coach profile. Within your profile, an
                                    option to set grading scale is present
                                    <br />
                                    <br />
                                    <li> How do I set my intangibles?</li>
                                    You can set your intangibles our
                                    Grading/Intangibles Section. You can
                                    add/edit/remove any intangibles.
                                    <br /> <br />
                                    <li> How do I contact support?</li>
                                    Click the green support icon in the bottom
                                    right corner of any screen and submit your
                                    support request. Our support team will reply
                                    as soon as possible.
                                    <br /> <br />
                                  </ul>
                                </Label>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                    </DivBlock>
                  </DivBlock>
                  <DivBlock></DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => state.reducer;

export default connect(mapStateToProps, null)(SupportPage);
