export const play_grading_table_columns = {
  "#": true,
  play: true,
  qtr: true,
  series: true,
  down: true,
  distance: true,
  type: true,
  package: true,
};

export const apiUrl = `/quickcut`;

export const qwikCutApiEndpoints = {
  authenticateUrl: "/authenticate",
  getUserTeams: `/user/{id}/teams`,
  getUserTeamsSchedule: `/user/{user_id}/team/{team_id}/schedule`,
  getUserTeamsSeasonSchedule: `/user/{user_id}/team/{team_id}/schedule/{season_id}`,
  getStatsColumns: `/stat-columns/{team_id}`,
  getGameStats: `/game/{game_id}/stats/{team_id}`,
};

const steps = [
  "Create or upload your player roster",
  "Create at least one Depth Chart",
  "Create a Game or Practice",
  "Add plays and map them to your depth chart",
  "Start grading players for each play added",
];

export const joyrideSteps = [
  {
    target: ".joyride-step",
    content: (
      <div className="joyride-content">
        <h3 className="joyride-title">Welcome to CoachTools!</h3>
        <p>There are 5 steps to getting set up:</p>
        <ol className="joyride-first-step">
          {steps.map((step, index) => (
            <li key={index} className="joyride-step-item">
              <span className="joyride-step-index">{index + 1}.</span>
              {step}
            </li>
          ))}
        </ol>
        <p>Let's start grading your players with ease!</p>
      </div>
    ),
    placement: "center",
    isFixed: true,
  },
  {
    target: ".joyride-roster",
    content: "Click 'Roster'.",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Click 'Roster'.</p>
      </div>
    ),
    isFixed: true,
  },
  {
    target: ".joyride-upload-roster",
    content: (
      <div className="upload-roster-content">
          <p className="joyride-text">
          Upload your player roster here as an Excel or CSV file, mapping player
          attributes to the CoachTools system.
        </p>
      </div>
    ),
    isFixed: true,
  },
  {
    target: ".joyride-depth-chart",
    content: (
      <div className="upload-roster-content">
         <p className="joyride-text">Click 'Depth Charts'.</p>
      </div>
    ),
  },
  {
    target: ".sidenav .joyride-depth-chart-open .joyride-add-depth-chart",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Click 'Add Depth Chart'.</p>
      </div>
    ),
  },
  {
    target: ".joyride-depth-chart-add-button",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Enter your Depth Chart info and click 'Add'.</p>
      </div>
    ),
    placement: "left",
  },
  // {
  //   target: ".test-depth-chart-add-button",
  //   content: "Click here to add your Depth Chart",
  // },
  {
    target: ".test-depth-chart-click-cell",
    content: (
      <div className="upload-roster-content">
          <p className="joyride-text">Click here to add players.</p>
      </div>
    ),
  },
  {
    target: ".test-depth-chart-name-search",
    content: (
      <div className="upload-roster-content">
         <p className="joyride-text">Type player name to search.</p>
      </div>
    ),
  },
  {
    target: ".test-depth_chart-submit-player",
    content: (
      <div className="upload-roster-content">
         <p className="joyride-text">Submit your player</p>
      </div>
    ),
  },
  {
    target: ".test-grading",
    content: (
      <div className="upload-roster-content">
       <p className="joyride-text">Click 'Grading' to start grading your players.</p>
      </div>
    ),
  },
  {
    target: ".test-grading-add-new-game-or-practice",
    content: (
      <div className="upload-roster-content">
       <p className="joyride-text">Click here to create your Game / Practice.</p>
      </div>
    ),
  },

  {
    target: ".logo-blk",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Hello </p>
      </div>
    ),
  },
];
