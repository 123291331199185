import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, DivBlock, LinkBlock, List, ListItem } from "../modules/Basic";
import { Column, Columns } from "../modules/Layout";
import { Heading, Paragraph, Span, TextLink } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";

class PlayReportsPage extends Component {

  componentDidUpdate(previousProps,previousState){
    const { dispatch } = this.props;
    if(previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear){
      dispatch({ type: "LOADING_EVENT",inputVariables:{loader:true} });
        this.props.getGamesData();
        this.props.getPracticesData()
      // dispatch({ type: "LOADING_EVENT",inputVariables:{loader:false} });
    }
  }

  render() {
    //let { row, index, errors } = this.props;
    let { Games, NavBarDropDownIndex, Practices ,selectedSeasonYear } = this.props;

    return (
      <Fragment>
        <Body
          actions={[
            {
              action: "Get Games",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Practices",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 1 : 1,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>
            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>
              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  paddingTop: "0px",
                  position:'relative',
                  height:'100%'
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"} style={{}}>
                      <DivBlock style={{}}>
                        <DivBlock
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Game & Practice Reports
                          </Heading>
                          <Span className={"h2 h2-span"} style={{}}>
                            Reports /
                          </Span>
                          <Span className={"h2 h2-inner-span"} style={{}}>
                            Game & Practice Reports
                          </Span>
                        </DivBlock>
                      </DivBlock>
                      <Columns
                        selectedLayout={"9/3"}
                        style={{
                          backgroundColor: "#ffffff",
                          fontSize: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        <Column lg={9} md={9} sm={9} xs={9}>
                          <DivBlock
                            style={{
                              alignItems: "center",
                              display: "flex",
                              fontSize: "13px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Paragraph
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                                paddingLeft: "10px",
                              }}
                            >
                              Choose a game or practice below to view
                              play-by-play metrics.
                            </Paragraph>
                          </DivBlock>
                        </Column>
                        <Column
                          lg={3}
                          md={3}
                          sm={3}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          xs={3}
                        >
                          <DivBlock
                            style={{
                              display: "flex",
                              flex: "1 0 auto",
                              justifyContent: "flex-end",
                              paddingRight: "10px",
                            }}
                          >
                            {/* <TextLink
                              href={"/reports"}
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                              }}
                              type={"spa"}
                            >
                              Back To Reports &gt;&gt;
                            </TextLink> */}
                          </DivBlock>
                        </Column>
                      </Columns>
                      <DivBlock className={"mb-4"}>
                        <Span
                          style={{
                            fontFamily:"'Poppins', sans-serif",
                            fontWeight: "600",
                            marginLeft: "10px",
                            marginRight: "0px",
                          }}
                        >
                          Games
                        </Span>
                        <List style={{ marginBottom: "0px", marginTop: "0px" }}>
                          {Games &&
                            isArray(Games) &&
                            Games.map((row, index) => {
                              return (
                                <Fragment key={index}>
                                  <ListItem style={{ listStyle: "square" }}>
                                    <LinkBlock
                                      className={"a"}
                                      duration={1000}
                                      href={
                                        "play_reports_data?playgradeid=" +
                                        row["Id"] + '&year='+ selectedSeasonYear
                                      }
                                      target={"_self"}
                                      type={"spa"}
                                    >
                                      <DivBlock>
                                        <Span className={"a package"}>
                                          {row["title"]}
                                        </Span>
                                      </DivBlock>
                                    </LinkBlock>
                                  </ListItem>
                                </Fragment>
                              );
                            })}
                        </List>
                      </DivBlock>
                      <DivBlock className={"mb-4"}>
                        <Span
                          style={{
                            fontFamily:"'Poppins', sans-serif",
                            fontWeight: "600",
                            marginLeft: "10px",
                            marginRight: "0px",
                          }}
                        >
                          Practices
                        </Span>
                        <List style={{ marginBottom: "0px", marginTop: "0px" }}>
                          {Practices &&
                            isArray(Practices) &&
                            Practices.map((row, index) => {
                              return (
                                <Fragment key={index}>
                                  <ListItem style={{ listStyle: "square" }}>
                                    <LinkBlock
                                      className={"a"}
                                      duration={1000}
                                      href={
                                        "play_reports_data?playgradeid=" +
                                        row["Id"]+ '&year='+ selectedSeasonYear
                                      }
                                      target={"_self"}
                                      type={"spa"}
                                    >
                                      <DivBlock>
                                        <Span
                                          className={"a package"}
                                          actions={[
                                            {
                                              action: "Set Depth Chart",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "depth chart": row,
                                              },
                                            },
                                          ]}
                                        >
                                          {row["title"]}
                                        </Span>
                                      </DivBlock>
                                    </LinkBlock>
                                  </ListItem>
                                </Fragment>
                              );
                            })}
                        </List>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGamesData: () => {
      dispatch({ type: "GET_GAMES" });
      return Promise.resolve();
    },
    getPracticesData: () => {
      dispatch({ type: "GET_PRACTICES" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayReportsPage);
