import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import {
  FormBlock,
  Label,
  SelectDropDown,
  TextInput,
  CheckBoxOption,
} from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { startPageTimer, trackPageDuration } from "../utils/function";

class HeadCoachPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
    };
  }

  componentDidMount(){
  startPageTimer('Coaches');
  this.setState({ startTime: Date.now() });
  }

  componentWillUnmount(){
  trackPageDuration('Coaches',this.state.startTime)
  }
  render() {

    let {
      CoachToDelete,
      CurrentDepthChart,
      CurrentUser,
      DuplicatePositionCoach,
      EditUser,
      FormEditPositionCoachError,
      FormEditUserErrors,
      FormPositionsCoachError,
      NavBarDropDownIndex,
      NewIntangible,
      NewPositionCoach,
      PositionCoaches,
      Positions,
      SuccessMessage,
      FormPositionsCoach,
      storeEditedPosition
    } = this.props;

    const OffenseCoach = ({ name,key }) => {
      return <div key={key}>
        <input
          type="checkbox"
          name={name}
          value={name}
          onChange={(e) => {
            this.props.editPositionCoach({
              position: name
            });
          }}
          checked={storeEditedPosition?.split(',')?.includes(name)}
        />
        <Label>{name}</Label>
      </div>
    }
    const DefenseCoach = ({ name,key }) => {
      return <div key={key}>
        <input
          type="checkbox"
          name={name}
          value={name}

          onChange={(e) => {
            this.props.editPositionCoach({
              position: name
            });
          }}
          checked={storeEditedPosition?.split(',')?.includes(name)}
        />
        <Label>{name}</Label>
      </div>
    }
    const SpecialTeams = ({ name,key }) => {
      return <div key={key}>
        <input
          type="checkbox"
          name={name}
          value={name}
          // id={item.Id}

          onChange={(e) => {
            // add to list
            this.props.editPositionCoach({
              position: name
            });

          }}
          checked={storeEditedPosition?.split(',')?.includes(name)}

        />
        <Label>{name}</Label>
      </div>
    }

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Position Coaches",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Positions",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Users",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: { "intangible id": 0 },
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 2 : 2,
              },
            },
            {
              action: "Remove SuccessEmailMessage",
              trigger: "onclick",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  position:'relative',
                  height:'100%'
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <Heading className={"h2"} style={{}} type={"h2"}>
                        Coaches
                      </Heading>

                      <Columns>
                        <Column
                          lg={12}
                          md={12}
                          sm={12}
                          style={{ marginBottom: "1rem" }}
                          xs={12}
                        >
                          <DivBlock
                            className={"PositionCoach-Success"}
                            style={{}}
                          >
                            <Span 
                              style={{ fontFamily:"'Poppins', sans-serif",  }}
                            >
                              {SuccessMessage}
                            </Span>
                          </DivBlock>

                          <DivBlock
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <If
                              condition={
                                CurrentUser["user_type"] === "Coach"
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Button
                                  className={"btn btn-primary"}
                                  actions={[
                                    {
                                      action: "New Position Coach",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        "input new exercise": true,
                                        "input new injured": true,
                                        "input new intangible": true,
                                        "input new position coach": true,
                                      },
                                    },
                                  ]}
                                >
                                  Add Coach
                                </Button>
                              </Then>
                            </If>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <DivBlock className="table-responsive-sm">      
                      <table className={"table"}>
                        <thead>
                          <tr className={"thead-bg"} style={{}}>
                            <th className={"th-border"}>
                              <Span className={"th-span"}>Name</Span>
                            </th>

                            <th className={"th-border"}>
                              <Span className={"th-span"}>Email</Span>

                              <Span></Span>
                            </th>

                            <th className={"th-border"}>
                              <Span className={"th-span"}>Category</Span>
                            </th>

                            <th className={"th-border"}>
                              <Span className={"th-span"}>Position</Span>
                            </th>
                            <th className={"th-border th-coach"} style={{}}>
                            <If
                              className={"Action-if"}
                              condition={
                                CurrentUser["user_type"] === "Coach"
                                  ? true
                                  : false
                              }
                            >
                              <Then style={{ display: "inline" }}>
                                  <Span className={"th-span"} style={{}}>
                                    Action
                                  </Span>
                              </Then>
                            </If>
                            </th>
                          </tr>
                        </thead>

                        <tbody
                          style={{ objectFit: "contain", overflow: "visible" }}
                        >
                          {PositionCoaches &&
                            isArray(PositionCoaches) &&
                            PositionCoaches.map((row, index) => {
                              return (
                                <Fragment key={index} >
                                  <tr>
                                    <td
                                      style={{
                                        alignItems: "center",
                                        borderBottomColor: "#000000",
                                        borderBottomStyle: "none",
                                        borderBottomWidth: "",
                                        borderLeftColor: "#000000",
                                        borderLeftStyle: "none",
                                        borderLeftWidth: "",
                                        borderRightColor: "#000000",
                                        borderRightStyle: "none",
                                        borderRightWidth: "",
                                        borderStyle: "none",
                                        borderTopColor: "#000000",
                                        borderTopStyle: "none",
                                        borderTopWidth: "",
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingBottom: "10px",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <If
                                        condition={
                                          row["user_type"] === "Coach"
                                            ? true
                                            : false
                                        }
                                      >
                                        <Then>
                                          <DivBlock>
                                            <Span
                                              style={{ marginRight: "2px" }}
                                            >
                                              *
                                            </Span>
                                          </DivBlock>
                                        </Then>
                                      </If>

                                      <Span className={"table-span"}>
                                        {row["name"]}
                                      </Span>
                                    </td>

                                    <td
                                      style={{
                                        borderBottomColor: "#000000",
                                        borderBottomStyle: "none",
                                        borderBottomWidth: "",
                                        borderLeftColor: "#000000",
                                        borderLeftStyle: "none",
                                        borderLeftWidth: "",
                                        borderRightColor: "#000000",
                                        borderRightStyle: "none",
                                        borderRightWidth: "",
                                        borderStyle: "none",
                                        borderTopColor: "#000000",
                                        borderTopStyle: "none",
                                        borderTopWidth: "",
                                        paddingBottom: "10px",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <Span className={"table-span"}>
                                        {row["email"]}
                                      </Span>
                                    </td>

                                    <td
                                      style={{
                                        borderBottomColor: "#000000",
                                        borderBottomStyle: "none",
                                        borderBottomWidth: "",
                                        borderLeftColor: "#000000",
                                        borderLeftStyle: "none",
                                        borderLeftWidth: "",
                                        borderRightColor: "#000000",
                                        borderRightStyle: "none",
                                        borderRightWidth: "",
                                        borderStyle: "none",
                                        borderTopColor: "#000000",
                                        borderTopStyle: "none",
                                        borderTopWidth: "",
                                        paddingBottom: "10px",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <Span className={"table-span"}>
                                        {row["category"]}
                                      </Span>
                                    </td>

                                    <td
                                      style={{
                                        borderBottomColor: "#000000",
                                        borderBottomStyle: "none",
                                        borderBottomWidth: "",
                                        borderLeftColor: "#000000",
                                        borderLeftStyle: "none",
                                        borderLeftWidth: "",
                                        borderRightColor: "#000000",
                                        borderRightStyle: "none",
                                        borderRightWidth: "",
                                        borderStyle: "none",
                                        borderTopColor: "#000000",
                                        borderTopStyle: "none",
                                        borderTopWidth: "",
                                        paddingBottom: "10px",
                                        paddingLeft: "0.75rem",
                                        paddingRight: "0.75rem",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <Span className={"table-span"}>
                                        {row["position"]}
                                      </Span>
                                    </td>
                                    <td
                                          style={{
                                            borderBottomColor: "#000000",
                                            borderBottomStyle: "none",
                                            borderBottomWidth: "",
                                            borderLeftColor: "#000000",
                                            borderLeftStyle: "none",
                                            borderLeftWidth: "",
                                            borderRightColor: "#000000",
                                            borderRightStyle: "none",
                                            borderRightWidth: "",
                                            borderStyle: "none",
                                            borderTopColor: "#000000",
                                            borderTopStyle: "none",
                                            borderTopWidth: "",
                                            display: "block",
                                            fontFamily:"'Poppins', sans-serif",
                                            paddingBottom: "10px",
                                            paddingLeft: "0.75rem",
                                            paddingRight: "0.75rem",
                                            paddingTop: "10px",
                                          }}
                                        >
                                    <If
                                      condition={
                                        CurrentUser["user_type"] === "Coach"
                                          ? true
                                          : false
                                      }
                                    >
                                      <Then>
                                      
                                          <DivBlock
                                            style={{
                                              alignItems: "center",
                                              display: "flex",
                                              fontFamily:"'Poppins', sans-serif",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Span
                                              className={"btn table-span"}
                                              actions={[
                                                {
                                                  action: "Set Edit User",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    "Edit User": row,
                                                  },
                                                },
                                                {
                                                  action: "Edit Coach Position",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: { "position": row?.position },
                                                },
                                              ]}
                                            >
                                              Edit
                                            </Span>

                                            <Span
                                              className={"btn table-span"}
                                              actions={[
                                                {
                                                  action: "Set Delete Coach",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    coachid: row["Id"],
                                                  },
                                                },
                                              ]}
                                            >
                                              Delete
                                            </Span>

                                            <Span
                                              className={"btn table-span"}
                                              actions={[
                                                {
                                                  action: "Resend Email",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                    positioncoachid: row["Id"],
                                                  },
                                                },
                                              ]}
                                            >
                                              Resend Email
                                            </Span>
                                          </DivBlock>
                                       
                                      </Then>
                                    </If>
                                    </td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                      </DivBlock>

                      <DivBlock
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                      >
                        <Span
                          style={{
                            color: "#404757",
                            fontFamily:"'Poppins', sans-serif",
                            fontSize: "13px",
                            fontStyle: "italic",
                            fontWeight: "400",
                          }}
                        >
                          * Indicates head coach
                        </Span>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>

            <If condition={NewPositionCoach ? true : false} show={false}>
              <Then>
                <DivBlock className={"modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            New Coach
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "New Position Coach",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input new position coach": false,
                                  "input new exercise": false,
                                  "input new intangible":
                                    NewIntangible !== true,
                                },
                              },
                              {
                                action: "Remove Errors",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create User",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <If
                              condition={DuplicatePositionCoach ? true : false}
                            >
                              <Then>
                                <DivBlock className={"invalid-feedback"}>
                                  <Span>{DuplicatePositionCoach}</Span>
                                </DivBlock>
                              </Then>
                            </If>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Name</Label>

                              <TextInput
                                className={"form-control"}
                                name={"FormPositionsCoach.name"}
                                placeholder={""}
                                actions={[
                                  {
                                    action: "Remove Errors",
                                    trigger: "onfocus",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              ></TextInput>

                              <Span className={"invalid-feedback"}>
                                {FormPositionsCoachError
                                  ? FormPositionsCoachError["name"]
                                  : ""}
                              </Span>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Email</Label>

                              <TextInput
                                className={"form-control"}
                                name={"FormPositionsCoach.email"}
                                placeholder={""}
                                actions={[
                                  {
                                    action: "Remove Errors",
                                    trigger: "onfocus",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              ></TextInput>

                              <Span className={"invalid-feedback"}>
                                {FormPositionsCoachError
                                  ? FormPositionsCoachError["email"]
                                  : ""}
                              </Span>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Category</Label>

                              <SelectDropDown
                                className={"form-control"}
                                initialValue={"Offense"}
                                name={"FormPositionsCoach.category"}
                                options={[
                                  { label: "Offense", value: "Offense" },
                                  { label: "Defense", value: "Defense" },
                                  {
                                    label: "Special Teams",
                                    value: "Special Teams",
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Position</Label>
                              {/* <DivBlock style={{"display":"flex"}} > */}
                              <Label className={"checkbox-label"}>Offense</Label>
                              {FormPositionsCoach &&
                                Positions?.map((item, index) => {
                                  if (item.category === "Offense") {
                                    return <div key={index} >
                                      <CheckBoxOption
                                        // initialValue={item.id}
                                        checkBoxName={item.name}
                                        name={`FormPositionsCoach.position name[${item.name}]`}
                                      />
                                    </div>;
                                  } else return null
                                })
                              }
                              <Label className={"checkbox-label"}>Defense</Label>
                              {FormPositionsCoach &&
                                Positions?.map((item, index) => {
                                  if (item.category === "Defense") {
                                    return <div key={index} >
                                      <CheckBoxOption
                                        // initialValue={item.id}
                                        checkBoxName={item.name}
                                        name={`FormPositionsCoach.position name[${item.name}]`}
                                      />
                                    </div>
                                  } else return null
                                })
                              }
                              <Label className={"checkbox-label"}>Special Teams</Label>
                              {FormPositionsCoach &&
                                Positions?.map((item, index) => {
                                  if (item.category === "Special Teams") {
                                    return <div key={index} >
                                      <CheckBoxOption
                                        // initialValue={item.id}
                                        checkBoxName={item.name}
                                        name={`FormPositionsCoach.position name[${item.name}]`}
                                      />
                                    </div>
                                  } else return null
                                })
                              }
                              {/* </DivBlock> */}

                              {/* <SelectDropDown
                                className={"form-control"}
                                dataSet={Positions}
                                dataSetLabel={"name"}
                                dataSetValue={"Id"}
                                initialValue={
                                  Positions && Positions.length > 0
                                    ? Positions[0].Id
                                    : 0
                                }
                                name={"FormPositionsCoach.position id"}
                                options={[]}
                                type={"number"}
                              ></SelectDropDown>

                              <If
                                condition={
                                  FormEditUserErrors &&
                                  FormEditUserErrors["position id"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormPositionsCoachError
                                      ? FormPositionsCoachError["position_id"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If> */
                                <Span className={"invalid-feedback"}>
                                  {FormPositionsCoachError
                                    ? FormPositionsCoachError["position_data"]
                                    : ""}
                                </Span>
                              }
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <DivBlock className={"form-group"}>
                                <Label className={"label"}>Status</Label>

                                <SelectDropDown
                                  className={"form-control"}
                                  initialValue={true}
                                  name={"FormPositionsCoach.active"}
                                  options={[
                                    { label: "Active", value: "true" },
                                    { label: "Inactive", value: "false" },
                                  ]}
                                  type={"plain"}
                                ></SelectDropDown>
                              </DivBlock>
                            </DivBlock>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Create New Position Coach",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Submit
                            </Button>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={EditUser ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  style={{}}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input new position": false },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input position": null },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Edit Coach
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Edit User",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "Edit User": null,
                                  "input position": null,
                                },
                              },
                              {
                                action: "Remove Errors",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create User",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Name</Label>

                              <TextInput
                                className={"form-control"}
                                enableReinitialize={true}
                                initialValue={EditUser ? EditUser["name"] : ""}
                                name={"FormEditPositionCoach.name"}
                                placeholder={""}
                                actions={[
                                  {
                                    action: "Remove Errors",
                                    trigger: "onfocus",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              ></TextInput>

                              <If
                                condition={
                                  FormEditPositionCoachError &&
                                    FormEditPositionCoachError["name"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormEditPositionCoachError
                                      ? FormEditPositionCoachError["name"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Email</Label>

                              <TextInput
                                className={"form-control"}
                                initialValue={EditUser ? EditUser["email"] : ""}
                                name={"FormEditPositionCoach.email"}
                                placeholder={""}
                                actions={[
                                  {
                                    action: "Remove Errors",
                                    trigger: "onfocus",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              ></TextInput>

                              <If
                                condition={
                                  FormEditPositionCoachError &&
                                    FormEditPositionCoachError["email"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormEditPositionCoachError
                                      ? FormEditPositionCoachError["email"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Category</Label>

                              <SelectDropDown
                                className={"form-control"}
                                initialValue={EditUser && EditUser.category}
                                name={"FormEditPositionCoach.category"}
                                options={[
                                  { label: "Offense", value: "Offense" },
                                  { label: "Defense", value: "Defense" },
                                  {
                                    label: "Special Teams",
                                    value: "Special Teams",
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Position</Label>
                              <Label className={"checkbox-label"}>Offense</Label>
                              {
                                Positions?.map((item,index) => item.category === "Offense" ? <OffenseCoach key={index} name={item.name} /> : null)
                              }
                              <Label className={"checkbox-label"}>Defense</Label>
                              {
                                Positions?.map((item,index) => item.category === "Defense" ? <DefenseCoach key={index} name={item.name} /> : null)
                              }
                              <Label className={"checkbox-label"}>Special Teams</Label>
                              {
                                Positions?.map((item,index) => item.category === "Special Teams" ? <SpecialTeams key={index} name={item.name} /> : null)
                              }
                              <If
                                condition={
                                  FormEditPositionCoachError &&
                                    FormEditPositionCoachError["position_data"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormEditPositionCoachError
                                      ? FormEditPositionCoachError["position_data"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                              {/* <Label className={"checkbox-label"}>Offense</Label>
                              {EditUser &&
                                Offense.map((item) => {
                                  {
                                    
                                  }
                                  // var temp=FormPositionsCoach&&FormPositionsCoach?.position_name;
                                  
                                  return (
                                    <CheckBoxOption
                                      initialValue={
                                        item.name === EditUser.position
                                          ? true
                                          : null
                                      }
                                      // position
                                      // selectedValue={item.name===EditUser.position?EditUser.position:null}
                                      checkBoxName={item.name}
                                      name={`FormEditPositionCoach.positions[${item.name}]`}
                                      actions={[
                                        {
                                          action: "Remove Errors",
                                          trigger: "onfocus",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {},
                                        },
                                      ]}
                                    />
                                  );
                                })} */}

                              {/* <Label className={"label"}>Defense</Label> */}
                              {/* {EditUser &&
                                Defense.map((item) => {
                                  return (
                                    <CheckBoxOption
                                      // initialValue={item.id}
                                      initialValue={
                                        item.name === EditUser.position
                                          ? EditUser.position
                                          : null
                                      }
                                      checkBoxName={item.name}
                                      name={`FormPositionsCoach.positions[${item.name}]`}
                                      actions={[
                                        {
                                          action: "Remove Errors",
                                          trigger: "onfocus",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {},
                                        },
                                      ]}
                                    />
                                  );
                                })} */}

                              {/* <Label className={"label"}>Special Teams</Label> */}

                              {/* {EditUser &&
                                Special.map((item) => {
                                  return (
                                    <CheckBoxOption
                                      // initialValue={item.id}
                                      initialValue={
                                        item.name === EditUser.position
                                          ? EditUser.position
                                          : null
                                      }
                                      checkBoxName={item.name}
                                      name={`FormPositionsCoach.position_id[${item.name}]`}
                                      actions={[
                                        {
                                          action: "Remove Errors",
                                          trigger: "onfocus",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {},
                                        },
                                      ]}
                                    />
                                  );
                                })} */}

                              {/* <SelectDropDown
                                className={"form-control"}
                                dataSet={Positions}
                                dataSetLabel={"name"}
                                dataSetValue={"Id"}
                                initialValue={
                                  EditUser && EditUser["position_id"]
                                    ? EditUser["position_id"]
                                    : Positions && Positions.length > 0
                                    ? Positions[0].Id
                                    : 0
                                }
                                name={"FormEditPositionCoach.position id"}
                                options={[]}
                                type={"number"}
                              ></SelectDropDown>

                              <If
                                condition={
                                  FormEditUserErrors &&
                                  FormEditUserErrors["position id"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormEditPositionCoachError
                                      ? FormEditPositionCoachError[
                                          "position_id"
                                        ]
                                      : ""}
                                  </Span>
                                </Then>
                              </If> */}
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label className={"label"}>Status</Label>

                              <SelectDropDown
                                className={"form-control"}
                                initialValue={
                                  EditUser && EditUser["active"]
                                    ? "true"
                                    : "false"
                                }
                                name={"FormEditPositionCoach.active"}
                                options={[
                                  { label: "Active", value: "true" },
                                  { label: "Inactive", value: "false" },
                                ]}
                                type={"boolean"}
                              ></SelectDropDown>

                              <If
                                condition={
                                  FormEditUserErrors &&
                                    FormEditUserErrors["active"]
                                    ? true
                                    : false
                                }
                                show={true}
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormEditPositionCoachError
                                      ? FormEditPositionCoachError["active"]
                                      : ""}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Update Position Coach",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: { },
                                },
                              ]}
                            >
                              Submit
                            </Button>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>

            <If condition={CoachToDelete ? true : false} show={false}>
              <Then>
                <DivBlock
                  className={"modal"}
                  actions={[
                    {
                      action: "Set New Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input new position": false },
                    },
                    {
                      action: "Edit Position",
                      trigger: "onclick",
                      timeout: "",
                      pollingPeriod: "",
                      inputVariables: { "input position": null },
                    },
                  ]}
                >
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading
                            className="modal-heading"
                            type={"h5"}
                          >
                            Delete Coach
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Set Delete Coach",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  coachid: null,
                                  "input edit depth chart": null,
                                  "input new row": false,
                                  "input position": null,
                                  "Depth Chart Id": null,
                                  "alias id input": null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            actions={[
                              {
                                action: "Create Depth Chart",
                                trigger: "onsubmit",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {},
                              },
                            ]}
                          >
                            <DivBlock>
                              <Label className={"label"}>
                                Are you sure you want to delete this coach?
                              </Label>
                            </DivBlock>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Set Delete Coach",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "Depth Chart Id": null,
                                      coachid: null,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Delete Position Coach",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "depth chart id to delete":
                                        CurrentDepthChart
                                          ? CurrentDepthChart["Id"]
                                          : "",
                                      "input delete position coach id":
                                        CoachToDelete,
                                    },
                                  },
                                ]}
                              >
                                Yes
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    editPositionCoach: (inputVariables) => {
      dispatch({ type: 'SET_COACH_POSITION', inputVariables });
      return Promise.resolve();
    },
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadCoachPage);
