import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux'
import { Autocomplete } from '@material-ui/lab';
import { addCurrentPositionData } from '../../../../redux/actions';


const CustomAutoComplete = ({ id, data, selectedValue, getTeamValue, label, name, className,...props }) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');


    useEffect(() => {
        selectedValue && setValue({
            title: selectedValue?.currentSelectedPositionData?.name,
            ...selectedValue?.currentSelectedPositionData
        })
    },[selectedValue])

    const  handleChange = (e, option) => {
        e?.preventDefault()
        setValue(option);
        name === "position_autocomplete" && dispatch(addCurrentPositionData({
                ...option
            }))
    }

    const handleInputChange = (e, data) => {
        setInputValue(String(data).replace(/ *\([^)]*\) */g, ""))
        name === "play_grade" && getTeamValue(data)
    }
    console.log('value :>> ', value);

    return (
        <div>
            <Autocomplete {...props} {...{ id }}
            className={className}
                options={data}
                value={value}
                inputValue={inputValue}
                onChange={(event, newValue) => {
                    handleChange(event, newValue)
                }}
                onInputChange={(event, newInputValue) => {
                    handleInputChange(event, newInputValue)
                }}
                getOptionLabel={(option) => option.title || ""}
                style={{ width: '100%' }}
                renderInput={(params) => <TextField style={{height: "45px"}} {...params} label={label} variant="outlined" />}
            />
        </div>
    )
}

export default CustomAutoComplete