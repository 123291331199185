/* eslint-disable no-useless-concat */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, DivBlock } from "../modules/Basic";
import { Col, Column, Columns, Row } from "../modules/Layout";
import { Else, If, Then } from "../modules/Logic";
import { Heading, Paragraph, Span, TextLink } from "../modules/Typography";
import PlayerSideNavComponent from "../components/PlayerSideNavComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { handleScaleTypeValue } from "../utils/function";
import BarChartLine from "../modules/charts/BarChartLine";
import { FormBlock, TextInput } from "../modules/Forms";
import { startPageTimer, trackPageDuration } from "../utils/function";

class PlayerGradesDataPage extends Component {

  constructor(props){
    super(props)
    this.state = {
      startTime: null,
    };
  }
  
  componentDidMount(){
    startPageTimer('Player Grades Data Page');
    this.setState({ startTime: Date.now() });
  }
  
  componentWillUnmount(){
    this.props?.clearPlayerAccountData();
    trackPageDuration('Player Grades Data Page',this.state.startTime);
  }
  
  handleAverages = (avgData) => {
    if (!avgData) return 'None'
    if (avgData && isArray(avgData)) {
      return `${Number(avgData?.map(value => Number(value?.integer_value))?.reduce((item, accumlator) => item + accumlator, 0) / avgData?.length).toFixed(2)}%`
    } else return "None"
  }
  
  render() {
    //let { row, index, errors } = this.props;
    let {
      AverageIntangible,
      CurrentGame,
      CurrentUser,
      GameDateResult,
      Intangibles,
      PlayerGradesUserCount,
      PlayerReportsData,
      TotalPlays,
      GetScaleTypeData,
      NoteInfo,
      NoteInfoData,
      CustomFieldTitles,
      PlayerProductionPointData,
      PlayerSortingTable,
      isDefaultLoaded
    } = this.props;

    const overallAverage =
    AverageIntangible.length > 0 ? AverageIntangible[0].overall_average : 0;

    return (
      <Fragment>
        <Body
          actions={[
            {
              action: "Get Game For Player",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {isDefault:true},
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              data-snippet-id={297}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <PlayerSideNavComponent
                data-snippet-id={302}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></PlayerSideNavComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  position:'relative',
                  height:'100%'
                }}
                className="main_section"
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"} style={{}}>
                      <DivBlock style={{}}>
                        <DivBlock
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Player Grades
                          </Heading>
                        </DivBlock>
                      </DivBlock>
                      {isDefaultLoaded ? null :
                      <>
                      <Columns
                        selectedLayout={"9/3"}
                        style={{
                          backgroundColor: "#ffffff",
                          fontSize: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        <Column
                          lg={9}
                          md={9}
                          sm={9}
                          style={{ paddingLeft: "10px" }}
                          xs={9}
                        >
                          <DivBlock
                            style={{
                              alignItems: "center",
                              display: "block",
                              fontSize: "13px",
                              justifyContent: "flex-start",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <Heading
                              style={{
                                alignItems: "flex-start",
                                display: "block",
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "16px",
                                fontWeight: "500",
                                justifyContent: "flex-start",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                              }}
                              type={"h1"}
                            >
                              {CurrentGame
                                ? CurrentGame["title"] +
                                  " " +
                                  `(` +
                                  GameDateResult +
                                  `)`
                                : ""}
                            </Heading>

                            <Span
                              style={{ fontFamily:"'Poppins', sans-serif", }}
                            >
                              {CurrentGame
                                ? "Type:" +
                                  " " +
                                  CurrentGame["type"] +
                                  " " +
                                  "/" +
                                  " " +
                                  "Total Plays:" +
                                  " " +
                                  (TotalPlays ? TotalPlays : "0")
                                : " "}
                            </Span>
                          </DivBlock>
                        </Column>

                        <Column
                          lg={3}
                          md={3}
                          sm={12}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            paddingRight: "10px",
                          }}
                          xs={12}
                        >
                          <DivBlock
                            className = "play-info"
                          >
                            <TextLink
                              href={"/player_grades"}
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                              }}
                              type={"spa"}
                            >
                              Choose another Game or Practice &gt;&gt;
                            </TextLink>
                          </DivBlock>
                        </Column>
                      </Columns>

                      <Columns
                        selectedLayout={"9/3"}
                        style={{
                          backgroundColor: "#ffffff",
                          fontSize: "0px",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <Column
                          lg={9}
                          md={9}
                          sm={9}
                          style={{ paddingLeft: "10px" }}
                          xs={9}
                        >
                          <DivBlock
                            style={{
                              alignItems: "center",
                              display: "block",
                              fontSize: "13px",
                              justifyContent: "flex-start",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <Heading
                              style={{
                                alignItems: "flex-start",
                                display: "block",
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "16px",
                                fontWeight: "600",
                                justifyContent: "flex-start",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                              }}
                              type={"h1"}
                            >
                              {CurrentUser ? CurrentUser["name"] : ""}
                            </Heading>

                            <Span
                              style={{ fontFamily:"'Poppins', sans-serif", }}
                            >
                              {PlayerGradesUserCount
                                ? PlayerGradesUserCount["count"] +
                                  " " +
                                  "/" +
                                  " " +
                                  (TotalPlays
                                    ? TotalPlays + " " + "Plays"
                                    : "0")
                                : " "}
                            </Span>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <Row style={{ marginTop: "1.25rem",marginBottom:'1.25rem' }} >
                        <Col sm={12} md={12} lg={12} xl={6}  >
                          <Heading className="h3" type="h3" >Averages</Heading>
                          <DivBlock
                                id="player_report_average"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "auto",
                                  flexWrap: "wrap",
                                }}
                              >
                                {AverageIntangible &&
                                isArray(AverageIntangible) &&
                                AverageIntangible?.length > 0 ? (
                                  <Fragment>
                                    <DivBlock
                                      style={{
                                        alignItems: "center",
                                        display: "block",
                                        fontSize: "13px",
                                        justifyContent: "flex-start",
                                        marginBottom: "10px",
                                        marginLeft: "0px",
                                        marginRight: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Heading
                                        type={"h1"}
                                        style={{
                                          backgroundColor: "#677bac",
                                          fontFamily: "'Poppins', sans-serif",
                                          fontSize: "50px",
                                          fontWeight: "500",
                                          padding: "8px 8px",
                                          margin: 0,
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        {Math.round(overallAverage)}
                                        <span style={{verticalAlign:"super",fontSize:"50%"}}>%</span>
                                      </Heading>
                                      <DivBlock
                                        style={{
                                          display: "inline-block",
                                          marginRight: "40px",
                                          marginTop: "5px",
                                          paddingLeft: "10px",
                                          width: "100%",
                                        }}
                                      >
                                        <Span
                                          className={"table-span"}
                                          style={{}}
                                        >
                                          Overall
                                        </Span>
                                      </DivBlock>
                                    </DivBlock>
                                    {AverageIntangible.map((row, index) => (
                                      <DivBlock
                                        key={index}
                                        style={{
                                          alignItems: "center",
                                          display: "block",
                                          fontSize: "13px",
                                          justifyContent: "flex-start",
                                          marginBottom: "10px",
                                          marginLeft: "0px",
                                          marginRight: "20px",
                                          marginTop: "10px",
                                          paddingTop: "0px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Heading
                                          style={{
                                            backgroundColor: "#ffffff",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontSize: "50px",
                                            fontWeight: "500",
                                            padding: "8px 8px",
                                            margin: 0,
                                            textAlign: "center",
                                          }}
                                          type={"h1"}
                                        >
                                          {Math.round(row["avg"])}
                                          <span style={{verticalAlign:"super",fontSize:"50%"}}>%</span>
                                        </Heading>

                                        <DivBlock
                                          style={{
                                            display: "inline-block",
                                            marginRight: "40px",
                                            marginTop: "5px",
                                            paddingLeft: "10px",
                                            width: "100%",
                                          }}
                                        >
                                          <Span
                                            className={"table-span"}
                                            style={{}}
                                          >
                                            {row["name"]}
                                          </Span>
                                        </DivBlock>
                                      </DivBlock>
                                    ))}
                                  </Fragment>
                                ) : (
                                  <Paragraph
                                    style={{
                                      fontFamily: "'Poppins', sans-serif",
                                      fontSize: "14px",
                                    }}
                                  >
                                    No grades have been entered for this player.
                                  </Paragraph>
                                )}
                              </DivBlock>
                          <DivBlock style={{margin:'10px 0'}}>
                            <Heading className="h3" type="h3" >Production</Heading>
                            {Object.entries(PlayerProductionPointData).length > 0 ? Object.entries(PlayerProductionPointData)?.map((item)=>
                              <DivBlock className={"div-para"}>
                                <Paragraph className={"para"}
                                style={{
                                  fontFamily:"'Poppins', sans-serif",
                                  fontSize: "14px",
                                  marginBottom: 0,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  marginTop: 0,
                                }}
                              >
                                {item[0]} : {item[1]}
                              </Paragraph>
                              </DivBlock>
                            ) :
                            <Paragraph 
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px"
                              }}>
                              No production points tracked.
                            </Paragraph>}
                          </DivBlock>
                        </Col>
                        <Col className="hide-chart" sm={12} md={12} lg={12} xl={6}>
                            <div>
                              {AverageIntangible && isArray(AverageIntangible) ? <Fragment>
                                <Heading className="h3" type="h3" >Averages Chart</Heading>
                                <DivBlock style={{ width: "auto" }}>
                                {/* <BarChartLine {...{isSkipYaxis: false, maintainAspectRatio: false ,lengthData:AverageIntangible?.length}} chartData={AverageIntangible.map(val => Math.round(val.avg))} chartLabels={AverageIntangible.map(val => val.name)} /> */}
                                <BarChartLine
                                  isSkipYaxis={false}
                                  barBorderRadius={10}
                                  maintainAspectRatio={false}
                                  lengthData={
                                    AverageIntangible.length + 1
                                  }
                                  chartData={AverageIntangible.map(val => Math.round(val.avg))} 
                                  chartLabels={AverageIntangible.map(val => val.name)}
                                />
                                </DivBlock>
                              </Fragment> : null}
                            </div>
                        </Col>
                      </Row> 

                      <If
                        condition={
                          PlayerReportsData && PlayerReportsData.length > 0
                            ? true
                            : false
                        }
                      >
                        <Then>
                          <DivBlock
                            style={{
                              marginBottom: "10px",
                              marginLeft: "0px",
                              marginTop: "10px",
                            }}
                          >
                            <Paragraph
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                              }}
                            >
                              Click any table heading to sort by column.
                            </Paragraph>
                          </DivBlock>
                          <DivBlock style={{ overflowX: "auto" }}>
                          <DivBlock className="table-responsive-sm">
                            <table className={"table hide-last-col"}>
                              <thead>
                                <tr className={"thead-bg"}>
                                  <th className={"th-border"}>
                                    <Span
                                      className={"Sorting-span th-span"}
                                      actions={[
                                        {
                                          action: "TOGGLESORTINGPLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTogglePlayerName: {
                                              "TogglePlayerSorting": PlayerSortingTable !== true,
                                              "sortingPlayerField": "index"
                                            }
                                          },
                                        },
                                      ]}>
                                      #
                                    </Span>
                                  </th>

                                  <th className={"th-border"}>
                                    <Span
                                      className={"Sorting-span th-span"}
                                      actions={[
                                        {
                                          action: "TOGGLESORTINGPLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTogglePlayerName: {
                                              "TogglePlayerSorting": PlayerSortingTable !== true,
                                              "sortingPlayerField": "name"
                                            }
                                          },
                                        },
                                      ]}>
                                      Name
                                    </Span>
                                  </th>

                                  <th className={"th-border"}>
                                    <Span
                                      className={"Sorting-span th-span"}
                                      actions={[
                                        {
                                          action: "TOGGLESORTINGPLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTogglePlayerName: {
                                              "TogglePlayerSorting": PlayerSortingTable !== true,
                                              "sortingPlayerField": "type"
                                            }
                                          },
                                        },
                                      ]}>
                                      Type
                                    </Span>
                                  </th>

                                  <th className={"th-border"}>
                                    <Span
                                      className={"Sorting-span th-span"}
                                      actions={[
                                        {
                                          action: "TOGGLESORTINGPLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTogglePlayerName: {
                                              "TogglePlayerSorting": PlayerSortingTable !== true,
                                              "sortingPlayerField": "qtr"
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      Qtr
                                    </Span>
                                  </th>

                                  <th className={"th-border"}>
                                    <Span
                                      className={"Sorting-span th-span"}
                                      actions={[
                                        {
                                          action: "TOGGLESORTINGPLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTogglePlayerName: {
                                              "TogglePlayerSorting": PlayerSortingTable !== true,
                                              "sortingPlayerField": "series"
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      Series
                                    </Span>
                                  </th>

                                  <th className={"th-border"}>
                                    <Span
                                      className={"Sorting-span th-span"}
                                      actions={[
                                        {
                                          action: "TOGGLESORTINGPLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTogglePlayerName: {
                                              "TogglePlayerSorting": PlayerSortingTable !== true,
                                              "sortingPlayerField": "down"
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      Down
                                    </Span>
                                  </th>

                                  <th className={"th-border"}>
                                    <Span
                                      className={"Sorting-span th-span"}
                                      actions={[
                                        {
                                          action: "TOGGLESORTINGPLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTogglePlayerName: {
                                              "TogglePlayerSorting": PlayerSortingTable !== true,
                                              "sortingPlayerField": "title"
                                            }
                                          },
                                        },
                                      ]}>
                                      Package
                                    </Span>
                                  </th>

                                  <th className={"th-border"}>
                                    <Span
                                      className={"Sorting-span th-span"}
                                      actions={[
                                        {
                                          action: "TOGGLESORTINGPLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            inputTogglePlayerName: {
                                              "TogglePlayerSorting": PlayerSortingTable !== true,
                                              "sortingPlayerField": "production_point"
                                            }
                                          },
                                        },
                                      ]}>
                                      PP
                                    </Span>
                                  </th>
                                  <th className={"th-border"}>
                                    <Span
                                      className={"th-span"}>
                                      Notes
                                    </Span>
                                  </th>
                                  {CustomFieldTitles&& isArray(CustomFieldTitles) && CustomFieldTitles.map((val,index)=>{
                                  return(
                                    <th className={"th-border"} style={{ width: "auto", minWidth: "120px" }} key={index}>
                                      <Span
                                        className={"th-span"}>
                                        {val.title}
                                      </Span>
                                    </th>)})}

                                  {Intangibles &&
                                    isArray(Intangibles) &&
                                    Intangibles.map((row, index) => {
                                      return (
                                        <Fragment>
                                          <th className={"th-border"}>
                                            <Span className={"th-span"}>
                                              {`${row["name"]}`}
                                            </Span>
                                          </th>
                                        </Fragment>
                                      );
                                    })}
                                  <th className={"th-border"}>
                                  <Span className={"th-span"}>
                                    AVG
                                  </Span>
                                </th>
                                </tr>
                              </thead>

                              <tbody>
                                {PlayerReportsData &&
                                  isArray(PlayerReportsData) &&
                                  PlayerReportsData.map((row, index) => {
                                    return (
                                      <Fragment key={row?.id}>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              {row["index"]}
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              {row["name"]}
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              {row["type"]}
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              {row["qtr"]}
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              {row["series"]}
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              {row["down"]}
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              {row["title"]}
                                            </Span>
                                          </td>
                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              {row["production_point"]}
                                            </Span>
                                          </td>
                                          <td
                                            style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              paddingBottom: "10px",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Span className={"table-span"}
                                              actions={[
                                                {
                                                  action: "Set Notes",
                                                  trigger: "onclick",
                                                  timeout: "",
                                                  pollingPeriod: "",
                                                  inputVariables: {
                                                  "set notes":true,
                                                  "set notes data":row,
                                                  },
                                                },
                                              ]}>
                                              <i className="fa fa-eye" df-snippet-instance-id={298} style={{cursor:"pointer"}}/>
                                          </Span>
                                          </td>
                                          {CustomFieldTitles && isArray(CustomFieldTitles) && CustomFieldTitles.map((item,index)=>{
                                          let customData = row?.["custom_fields"] && isArray(row?.["custom_fields"]) 
                                          && row?.["custom_fields"].filter(inItem => inItem.title_id === item.id)
                                          return (
                                            <td key={index}
                                              style={{
                                                borderBottomColor: "#000000",
                                                borderBottomStyle: "none",
                                                borderBottomWidth: "",
                                                borderLeftColor: "#000000",
                                                borderLeftStyle: "none",
                                                borderLeftWidth: "",
                                                borderRightColor: "#000000",
                                                borderRightStyle: "none",
                                                borderRightWidth: "",
                                                borderStyle: "none",
                                                borderTopColor: "#000000",
                                                borderTopStyle: "none",
                                                borderTopWidth: "",
                                                paddingBottom: "10px",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "10px",
                                              }}
                                            >
                                              <Span className={"table-span"}>
                                                {isArray(customData) && customData[0]?.data?customData[0]?.data:'-'}
                                              </Span>
                                            </td>
                                              )
                                            })}
                                          {Intangibles &&
                                            isArray(Intangibles) &&
                                            Intangibles.map((item, index) => {
                                              let value =
                                                row["user_grades"] &&
                                                isArray(row["user_grades"]) &&
                                                row["user_grades"]?.filter(
                                                  (innItem) =>
                                                    innItem?.intangible_name ===
                                                    item?.["name"]
                                                );
                                                let getScaleTypeArray = [];
                                                if (value && isArray(value)) {
                                                  getScaleTypeArray =
                                                    isArray(GetScaleTypeData) && GetScaleTypeData?.filter(
                                                      (val) =>
                                                        val?.position_id ===
                                                        value[0]?.position_id
                                                    );
                                                }

                                              return (
                                                <Fragment>
                                                  <td
                                                    style={{
                                                      borderBottomColor:
                                                        "#000000",
                                                      borderBottomStyle: "none",
                                                      borderBottomWidth: "",
                                                      borderLeftColor:
                                                        "#000000",
                                                      borderLeftStyle: "none",
                                                      borderLeftWidth: "",
                                                      borderRightColor:
                                                        "#000000",
                                                      borderRightStyle: "none",
                                                      borderRightWidth: "",
                                                      borderStyle: "none",
                                                      borderTopColor: "#000000",
                                                      borderTopStyle: "none",
                                                      borderTopWidth: "",
                                                      fontSize: "0px",
                                                      marginTop: "0px",
                                                      paddingBottom: "0.375em",
                                                      paddingLeft: "0.75rem",
                                                      paddingRight: "0.75rem",
                                                      paddingTop: "0.375em",
                                                    }}
                                                  >
                                                    <Span
                                                      className={"table-span"}
                                                    >
                                                       {handleScaleTypeValue(getScaleTypeArray?.[0]?.scale_type,value)}
                                                    </Span>
                                                  </td>
                                                </Fragment>
                                              );
                                            })}
                                            <td style={{
                                              borderBottomColor: "#000000",
                                              borderBottomStyle: "none",
                                              borderBottomWidth: "",
                                              borderLeftColor: "#000000",
                                              borderLeftStyle: "none",
                                              borderLeftWidth: "",
                                              borderRightColor: "#000000",
                                              borderRightStyle: "none",
                                              borderRightWidth: "",
                                              borderStyle: "none",
                                              borderTopColor: "#000000",
                                              borderTopStyle: "none",
                                              borderTopWidth: "",
                                              fontSize: "0px",
                                              marginTop: "0px",
                                              paddingBottom: "0.375em",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375em",
                                            }}>
                                            <Span className={"table-span"} style={{ fontWeight: "700" }}>
                                              {this.handleAverages(row['user_grades'])}
                                            </Span>
                                        </td>
                                        </tr>
                                      </Fragment>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </DivBlock>
                          </DivBlock>
                          <DivBlock>
                        <If condition={NoteInfo ? true : false} show={false}>
                          <Then>
                            <DivBlock
                              className={"modal"}
                            >
                              <DivBlock className={"modal-dialog"} style={{}}>
                                <DivBlock className={"modal-dialog"} style={{}}>
                                  <DivBlock className={"modal-content"} style={{}}>
                                    <DivBlock className={"modal-header"}>
                                      <Heading
                                        className="modal-heading"
                                        type={"h5"}
                                      >
                                        Notes
                                      </Heading>

                                      <Span
                                        className={"close"}
                                        actions={[
                                          {
                                            action: "Set Notes",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              "toggle depth chart": false, 
                                            },
                                          },
                                        ]}
                                      >
                                        x
                                      </Span>
                                    </DivBlock>
                                  

                                    <DivBlock className={"modal-body"}>
                                      <FormBlock
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "100%",
                                        }}>
                                        <DivBlock style={{textAlign:'center'}}> 
                                        {NoteInfoData && (NoteInfoData.notes === "" || NoteInfoData.notes === null)?
                                         <Span
                                         style={{
                                           fontFamily:"'Poppins', sans-serif",
                                           fontSize: "14px",
                                           fontWeight: "400",
                                           textAlign: "center",
                                         }}
                                       >
                                         No notes available
                                       </Span>
                                        :
                                        <TextInput
                                          className={"form-control"}
                                          name={"NoteInfoData?.notes"}
                                          placeholder={""}
                                          initialValue={NoteInfoData?.notes?NoteInfoData.notes:"No notes available"}
                                          disabled={true}
                                          style={{}}
                                        ></TextInput>}
                                        </DivBlock>
                                      </FormBlock>
                                    </DivBlock>
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </DivBlock>
                          </Then>
                        </If>
                      </DivBlock>
                        </Then>

                        <Else>
                          <DivBlock
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          >
                            <Span
                              style={{
                                fontFamily:"'Poppins', sans-serif",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              Sorry, but you have not been added to any depth
                              charts yet.
                            </Span>
                          </DivBlock>
                        </Else>
                      </If></>}
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getScaleData: () => {
      dispatch({ type: "GET_SCALE_TYPE" });
      return Promise.resolve();
    },
    clearPlayerAccountData: () =>{
      dispatch({type:"CLEAR_PLAYER_ACCOUNT_VALUES"});
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerGradesDataPage);
