import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, DivBlock } from "../modules/Basic";
import { Heading, Span } from "../modules/Typography";
import PlayerSideNavComponent from "../components/PlayerSideNavComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { startPageTimer, trackPageDuration } from "../utils/function";

class PlayerDepthChartPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      startTime: null,
    };
  }
  
  componentDidMount(){
    startPageTimer('Player Depth Chart Page');
    this.setState({ startTime: Date.now() });
  }


  componentWillUnmount(){
    trackPageDuration('Player Depth Chart Page',this.state.startTime);
  }


  render() {
    let { CurrentDepthChart, DepthChartUsers } = this.props;
    const { FilterList, Find } = window;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Depth Chart",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Player Depth Chart Users",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Positions",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <PlayerSideNavComponent
                context={{
                  elements: {
                    1985: { type: "Body" },
                    1986: { type: "DivBlock" },
                    1987: { type: "DivBlock" },
                    1992: { type: "DivBlock" },
                    2007: { type: "DivBlock" },
                    2008: { type: "DivBlock" },
                    2009: { type: "DivBlock" },
                    2010: { type: "DivBlock" },
                    2011: { type: "If" },
                    2059: { type: "List" },
                    2060: { type: "ListItem" },
                    2061: { type: "LinkBlock" },
                    2062: { type: "DivBlock" },
                    2063: { type: "Span" },
                    2064: { type: "ListItem" },
                    2065: { type: "LinkBlock" },
                    2066: { type: "DivBlock" },
                    2067: { type: "Span" },
                    2068: { type: "ListItem" },
                    2069: { type: "LinkBlock" },
                    2070: { type: "DivBlock" },
                    2071: { type: "Span" },
                    2171: { type: "DivBlock" },
                  },
                  getters: {},
                  listeners: [
                    { name: "mouseOver.boundaries" },
                    { name: "mouseOut.boundaries" },
                    { name: "dragEnter" },
                    { name: "dragLeave" },
                    { name: "dragStart" },
                    { name: "dragEnd" },
                    { name: "mouseDown.mousemenu" },
                    { name: "showSettingsBox.desk" },
                    { name: "textChanged.desk" },
                    { name: "editTextMode.desk" },
                    { name: "componentClick.desk" },
                    { name: "pathnameChanged.desk" },
                    { name: "cut.desk" },
                    { name: "copy.desk" },
                    { name: "editSnippet.desk" },
                    { name: "clone.desk" },
                    { name: "delete.desk" },
                    { name: "before.desk" },
                    { name: "after.desk" },
                    { name: "first.desk" },
                    { name: "last.desk" },
                    { name: "replace.desk" },
                    { name: "select.desk" },
                    { name: "highlight.desk" },
                    { name: "dropZoneHover" },
                    { name: "onDragEnd" },
                    { name: "copyStyleProps" },
                    { name: "pasteStyleProps" },
                    { name: "updateSelectedProps" },
                  ],
                  triggerCache: {
                    componentClick: [{ name: "componentClick.desk" }],
                    editSnippet: [{ name: "editSnippet.desk" }],
                    editTextMode: [{ name: "editTextMode.desk" }],
                    mouseDown: [{ name: "mouseDown.mousemenu" }],
                    mouseOut: [{ name: "mouseOut.boundaries" }],
                    mouseOver: [{ name: "mouseOver.boundaries" }],
                    pathnameChanged: [{ name: "pathnameChanged.desk" }],
                    textChanged: [{ name: "textChanged.desk" }],
                  },
                }}
                data-snippet-id={302}
                df-snippet-instance-id={302}
                draggable={true}
                eleKey={"2171"}
                inline={{ "df-snippet-instance-id": 302, draggable: true }}
                isEdit={true}
                root-snippet={true}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></PlayerSideNavComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  position:'relative',
                  height:'100%'
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <DivBlock>
                        <DivBlock>
                          <Heading className={"h2"} type={"h3"}>
                            {CurrentDepthChart
                              ? CurrentDepthChart["title"]
                              : ""}
                          </Heading>
                        </DivBlock>
                        <DivBlock className="table-responsive-sm">
                        <table className={"table redTable"} style={{}}>
                          <thead>
                            <tr className={"thead-bg"}>
                              <th className={"RedTableCell th-border"}>
                                <Span className={"th-span"}>Position</Span>
                              </th>

                              <th className={"RedTableCell th-border"}>
                                <Span className={"th-span"}>Alias</Span>
                              </th>

                              <th className={"RedTableCell th-border"}>
                                <Span className={"th-span"}>Starter</Span>
                              </th>

                              <th className={"redTableCell"} style={{}}>
                                <Span className={"th-span"}>Second</Span>
                              </th>

                              <th className={"redTableCell"}>
                                <Span className={"th-span"}>Third</Span>
                              </th>

                              <th className={"redTableCell"}>
                                <Span className={"th-span"}>Fourth</Span>
                              </th>

                              <th className={"redTableCell"}>
                                <Span className={"th-span"}>Fifth</Span>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {FilterList({
                              searchData: DepthChartUsers,
                              property: "category",
                              query: CurrentDepthChart
                                ? CurrentDepthChart["category"]
                                : "",
                            }) &&
                              isArray(
                                FilterList({
                                  searchData: DepthChartUsers,
                                  property: "category",
                                  query: CurrentDepthChart
                                    ? CurrentDepthChart["category"]
                                    : "",
                                })
                              ) &&
                              FilterList({
                                searchData: DepthChartUsers,
                                property: "category",
                                query: CurrentDepthChart
                                  ? CurrentDepthChart["category"]
                                  : "",
                              }).map((row, index) => {
                                return (
                                  <Fragment>
                                    <tr>
                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder"
                                            : "redTableCell"
                                        }
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <Span className={"a a-link"}>
                                          {row["abbreviation"]}
                                        </Span>
                                      </td>

                                      <td
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <Span className={"table-span"}>
                                          {row.alias}
                                        </Span>
                                      </td>

                                      <td className={"redTableCell"} style={{}}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "static",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "current depth chart position input":
                                                  {
                                                    "position id": row["id"],
                                                    rank: 1,
                                                  },
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 1,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td className={"redTableCell"} style={{}}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "static",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "current depth chart position input":
                                                  {
                                                    "position id": row["id"],
                                                    rank: 2,
                                                  },
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 2,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td className={"redTableCell"} style={{}}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "static",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "current depth chart position input":
                                                  {
                                                    "position id": row["id"],
                                                    rank: 3,
                                                  },
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 3,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td className={"redTableCell"} style={{}}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "static",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "current depth chart position input":
                                                  {
                                                    "position id": row["id"],
                                                    rank: 4,
                                                  },
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 4,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td className={"redTableCell"} style={{}}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "static",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "current depth chart position input":
                                                  {
                                                    "position id": row["id"],
                                                    rank: 5,
                                                  },
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 5,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                      </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(PlayerDepthChartPage);
